import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Lobby from "../views/Lobby.vue";
import MyFighters from "../views/MyFighters.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: { title: "Dinos fight" },
  },
  {
    path: "/lobby",
    name: "lobby",
    component: Lobby,
    meta: { title: "Dinos fight" },
  },
  {
    path: "/my-fighters",
    name: "myFighters",
    component: MyFighters,
    meta: { title: "Dinos fight" },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
