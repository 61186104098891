<template>
  <div class="fight-wrapper fight-wrapper--result">
    <div class="fight-wrapper__top">
      <h2 class="fight-wrapper__top-round">ROUND {{ currentRound() - 1 }}</h2>
    </div>
    <div
      class="fight-layout"
      :class="{
        'fight-layout--success': win === true,
        'fight-layout--fail': !win,
      }"
    >
      <div class="fight-battle">
        <div class="fight-battle-player">
          <div class="fight-player fight-player--move">
            <div class="fighter">
              <div class="fighter__photo-container">
                <img
                  class="fighter__photo"
                  v-lazy="currentFighter.image"
                  alt=""
                />
              </div>
              <span class="fighter__name">{{ currentFighter.name }}</span>
              <div class="fighter__info">
                <span>LEVEL {{ currentFighter.level }}</span>
                <span>{{ currentFighter.health }}/100 HP</span>
              </div>
              <div class="fighter__progress">
                <span :style="`width: ${currentFighter.health}%`"></span>
              </div>
            </div>
            <img
              class="fight-player__hit"
              src="@/assets/images/fight-hit.png"
              alt=""
            />
            <div
              v-if="myMove && myMove !== 0"
              class="fight-player__move"
              :class="{
                'fight-player__move--success': win === true,
                'fight-player__move--fail': !win,
              }"
            >
              <img :src="getAttactImage(myMove, win)" alt="" />
              <span>{{ getAttackString(myMove) }}!</span>
            </div>
            <template v-if="!finished">
              <div
                class="fight-player__damage"
                v-if="!win"
              >
                {{ win === false ? -35 : -15 }}
              </div>
            </template>
          </div>
        </div>
        <div class="fight-battle-opponent">
          <div class="fight-opponent fight-opponent--move">
            <div class="fighter">
              <div class="fighter__photo-container">
                <img class="fighter__photo" v-lazy="opponent.image" alt="" />
              </div>
              <span class="fighter__name">{{ opponent.name }}</span>
              <div class="fighter__info">
                <span>LEVEL {{ opponent.level }}</span>
                <span>{{ opponent.health }}/100 HP</span>
              </div>
              <div class="fighter__progress">
                <span :style="`width: ${opponent.health}%`"></span>
              </div>
            </div>
            <div
              v-if="opponentMove && opponentMove !== 0"
              class="fight-opponent__move"
              :class="{
                'fight-player__move--success': win === false,
                'fight-player__move--fail': win === true || win === null,
              }"
            >
              <img
                :src="getAttactImage(opponentMove, win === null ? !!win : !win)"
                alt=""
              />
              <span>{{ getAttackString(opponentMove) }}!</span>
            </div>
            <template v-if="!finished">
              <div
                class="fight-opponent__damage"
                v-if="win === true || win === null"
              >
                {{ win === true ? -35 : -15 }}
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "FightRound",
  computed: {
    ...mapState([
      "currentFighter",
      "opponent",
      "moves",
      "myMove",
      "opponentMove",
      "finished",
      "win",
    ]),
  },
  methods: {
    ...mapGetters(["currentRound", "useFCContract"]),
    getAttackString(move) {
      return this.moves.find((e) => e.move === move).name;
    },
    getAttactImage(move, win) {
      return require("@/assets/images/" +
        this.moves.find((e) => e.move === move)[
          win === true ? "winIcon" : win === false ? "loseIcon" : "loseIcon"
        ]);
    },
  },
  mounted() {
    if (this.finished) {
      setTimeout(() => this.$store.commit("gameFinished"), 3000);
    } else {
      this.$store.commit("RESET");
      setTimeout(() => this.$store.commit("SET_CURRENT_MODAL", 2), 3000);
    }
  },
};
</script>

<style lang="scss" scoped>
.fight-player {
  margin-top: 120px;
}
.fight-layout {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fight-layout--success::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(100, 203, 52, 0) 0%,
    rgba(100, 203, 52, 0.0975) 70.83%,
    rgba(100, 203, 52, 0.75) 88.02%,
    rgba(58, 224, 0, 0.75) 100%
  );
  opacity: 0.33;
  z-index: -1;
}

.fight-layout--fail::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(142, 0, 0, 0) 0%,
    rgba(147, 1, 1, 0.13) 60.94%,
    #b30707 100%
  );
  opacity: 0.33;
  z-index: -1;
}
.fight-battle {
  display: flex;
  justify-content: center;
  align-items: center;
}
.fight-battle-player {
  z-index: 1;
  margin-right: 220px;
  margin-top: 50px;
  width: 100%;
  @include mq("tablet-wide", max) {
    margin-right: 0;
  }
}
.fight-battle-opponent {
  width: 100%;
  margin-top: -200px;
  @include mq("tablet-wide", max) {
    margin-top: -120px;
  }
}
.fight-battle .fighter {
  width: 100%;
  max-width: 280px;
  @include mq("tablet-wide", max) {
    max-width: 240px;
  }
  @include mq("tablet", max) {
    max-width: 190px;
  }
  @include mq("tablet-small", max) {
    max-width: 150px;
  }
}

.fight-player {
  align-self: flex-start;
}

.fight-player__hit {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 158px;
  height: 145px;
  transform: rotate(-16deg) translate(66px, -62px);
  transform-origin: top right;
  @include mq("tablet-wide", max) {
    top: 19px;
    right: 15px;
    width: 100px;
    height: 98px;
  }
}
.fight-opponent {
  align-self: flex-start;
}

/* fight moves */
.fight-player,
.fight-opponent {
  position: relative;
  transform-origin: bottom right;
  transition: transform 0.3s ease-in;
}

.fight-player--move {
  transform: rotate(16deg) translate(28px, -25px);

  @include mq("tablet-wide", max) {
    transform: rotate(12deg) translate(-70px, -25px);
  }

  @include mq("tablet-small", max) {
    transform: rotate(8deg) translate(-30px, -25px);
  }

  @include mq("phablet", max) {
    transform: rotate(8deg) translate(-27px, -88px);
  }
}
.fight-opponent--move {
  transform: rotate(-16deg) translate(-28px, -25px);

  @include mq("tablet-wide", max) {
    transform: rotate(-12deg) translate(8px, -25px);
  }
  @include mq("tablet-small", max) {
    transform: rotate(-8deg) translate(12px, -61px);
  }
  @include mq("phablet", max) {
    transform: rotate(-8deg) translate(30px, -61px);
  }
}
.fight-player__move,
.fight-opponent__move {
  display: block;
  position: absolute;
  top: -60px;
  width: 336px;
  height: 190px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Dinosaur;
  font-size: 48px;
  -webkit-text-stroke: 4px #fff;
  z-index: 0;

  @include mq("tablet-small", max) {
    font-size: 32px;
    -webkit-text-stroke: 2px #ffffff;
    img {
      width: 70px;
    }
  }
}
.fight-player__move {
  transform: rotate(-32deg) translate(-120px, -90px);

  @include mq("tablet-small", max) {
    transform: rotate(-28deg) translate(-88px, -136px);
  }
}
.fight-opponent__move {
  transform: rotate(32deg) translate(70px, -70px);

  @include mq("tablet-small", max) {
    transform: rotate(22deg) translate(-48px, -70px);
  }
}
.fight-player__move--success,
.fight-opponent__move--success {
  display: flex;
  color: #72cb34;
}
.fight-player__move--success::before,
.fight-opponent__move--success::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #72cb34 39.06%,
    rgba(80, 155, 28, 0) 100%
  );
  opacity: 0.75;
  z-index: -1;
}
.fight-player__move--fail,
.fight-opponent__move--fail {
  display: flex;
  color: #df1a1a;
}
.fight-player__move--fail::before,
.fight-opponent__move--fail::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #df1a1a 39.06%,
    rgba(97, 15, 15, 0) 100%
  );
  opacity: 0.75;
  z-index: -1;
}
.fight-player__damage,
.fight-opponent__damage {
  display: flex;
  position: absolute;
  bottom: 0;
  right: -150px;
  width: 246px;
  height: 140px;
  justify-content: center;
  align-items: center;
  font-family: Dinosaur;
  font-size: 52px;
  color: #df1a1a;
  -webkit-text-stroke: 4px #fff;
  text-shadow: 0px 4.25959px 4.92192px rgba(0, 0, 0, 0.25),
    0px 4.92192px 4.92192px rgba(0, 0, 0, 0.25);
  z-index: 0;
  @include mq("phablet", max) {
    font-size: 32px;
    -webkit-text-stroke: 2px #fff;
  }
}
.fight-player__damage {
  @include mq('phablet', max) {
    
  }
}
.fight-opponent__damage {
  transform: rotate(32deg);
  @include mq("phablet", max) {
    transform: rotate(32deg) translate(-40px, 140px);
  }
}
.fight-player__damage::before,
.fight-opponent__damage::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #df1a1a 39.06%,
    rgba(97, 15, 15, 0) 100%
  );
  opacity: 0.75;
  z-index: -1;
}
</style>
