<template>
  <header id="header" class="header" :class="{
    'header--bg': hasBackground
  }">
    <nav class="header-nav container">
      <ul
        class="header-list"
        :class="{
          'close': !isMenuOpen,
        }"
      >
        <li class="header-list__item">
          <router-link
            :class="{ 'active': path == '/lobby' }"
            :to="{ name: 'lobby' }"
          >
            LOBBY
          </router-link>
        </li>
        <li class="header-list__item">
          <router-link
            :class="{ 'active': path == '/my-fighters' }"
            :to="{ name: 'myFighters' }"
          >
            MY FIGHTERS
          </router-link>
        </li>
        <li class="header-list__item">
          <a
            href="https://defidinos.gitbook.io/defi-dinos/"
            target="_blank"
            referrerpolicy="no-referrer"
          >
            GAME GUIDE
          </a>
        </li>
      </ul>
      <button class="header-menu" @click="onClickMenu">
        <div class="header-menu__toggle" :class="{ 'open': isMenuOpen }">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </button>
      <div class="header-actions">
        <button class="header-actions__item header-actions__toggle-sound" @click="toggleSound">
          <img v-if="toggleAudio" src="@/assets/icons/sound-on.svg" alt="" />
          <img v-else src="@/assets/icons/sound-off.svg" alt="" />
        </button>
        <div
          v-if="account"
          class="header-actions__item"
        >
          <span class="header-actions__item-label">{{ (Math.round(myDXP * 100) / 100) }}</span>
          <img
            src="@/assets/images/dxp_icon_black.png"
            class="header-actions__item-icon"
          />
        </div>
        <button class="header-actions__item" @click="handleWalletModal">
          <span class="mono">
            {{ connectLabel }}
          </span>
        </button>
      </div>
    </nav>
  </header>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { shortenAddress } from "../utils";
import { connectors } from "../connectors";

export default {
  name: "NavBar",
  computed: {
    path() {
      return this.$route.path;
    },
    ...mapState(["account", "wrongChainId", "myDXP", "library"]),
    ...mapGetters(['getWrongChainId']),
    connectLabel() {
      if (this.getWrongChainId) {
        return "Switch Network"
      }
      return this.account
        ? shortenAddress(this.account)
        : "Connect Wallet"
    }
  },
  data() {
    return {
      isMenuOpen: false,
      hasBackground: false,
      toggleAudio: true,
    };
  },
  watch:{
    $route (to, from){
      this.isMenuOpen = false;
    }
  },
  mounted() {
    this.connectWallet({ connector: connectors[0], first: 1 });
    this.changeColor();
    document.addEventListener('scroll', this.changeColor)
  },
  destroyed() {
    document.removeEventListener('scroll', this.changeColor);
  },
  methods: {
    ...mapActions(["connectWallet"]),
    ...mapMutations(["openWalletModal"]),
    shortenAddress,
    handleWalletModal() {
      if (this.getWrongChainId) {
        this.connectWallet({ connector: connectors[0], first: 0 });
      } else {
        this.openWalletModal();
      }
    },
    onClickMenu() {
      this.isMenuOpen = !this.isMenuOpen
    },
    changeColor() {
      if (document.scrollingElement.scrollTop > 50) {
        this.hasBackground = true
      } else {
        this.hasBackground = false;
      }
    },
    toggleSound() {
      this.$toggleAudio(!this.toggleAudio);
      this.toggleAudio = !this.toggleAudio;
    }
  },
};
</script>

<style lang="scss" scoped>
.header {
  position: fixed;
  height: 120px;
  z-index: 110;
  width: 100%;
  transition: 300ms all ease-in;
  &--bg {
    background:  rgba(1, 1, 1, 0.48);
  }
}
.header-nav {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 575px) {
    margin: 0;
    width: calc(100% - 24px);
  }
}
.header-list {
  display: flex;
  padding: 0;
  @include mq('tablet-wide', max) {
    z-index: 100;
    text-align: center;
    margin-top: 0;
    padding-top: 130px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000;
    flex-direction: column;
  }
  &.close {
    @include mq('tablet-wide', max) {
      display: none;
    }
  }
  &__item {
    margin-right: 48px;
    a {
      color: #FFFFFF;
      transition: all 200ms ease-in-out;
      font-size: 30px;
      &:hover {
        color: #ffa035;
      }
    }
    @include mq('desktop', max) {
      margin-right: 20px;
      a {
        font-size: 20px;
      }
    }
    @include mq('tablet-wide', max) {
      margin-right: 0;
      margin-bottom: 40px;
      a {
        font-size: 36px;
      }
    }
    .active {
      color: #ffa035;
    }
  }
}
.header-menu {
  display: none;
  @include mq('tablet-wide', max) {
    display: block;
  }
  &__toggle {
    width: 40px;
    height: 45px;
    position: relative;
    margin: 50px auto;
    transform: rotate(0deg);
    z-index: 120;
    transition: 500ms ease-in-out;
    cursor: pointer;
    span {
      display: block;
      position: absolute;
      height: 4px;
      width: 100%;
      background: #FFFFFF;
      border-radius: 9px;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: 250ms ease-in-out;
    }
    span:nth-child(1) {
      top: 7px;
      transform-origin: left center;
    }
    span:nth-child(2) {
      top: 20px;
      transform-origin: left center;
    }
    span:nth-child(3) {
      top: 33px;
      transform-origin: left center;
    }
    &.open span:nth-child(1) {
      transform: rotate(45deg);
      top: 7px;
      left: 6px;
    }
    &.open span:nth-child(2) {
      width: 0%;
      opacity: 0;
    }
    &.open span:nth-child(3) {
      transform: rotate(-45deg);
      top: 35px;
      left: 6px;
    }
  }
}
.header-actions {
  display: flex;
  align-items: center;
  &__item {
    color: #000000;
    width: auto;
    text-align: center;
    font-size: 18px;
    line-height: 34px;
    border: none;
    background-color: white;
    padding: 12px 16px;
    border-radius: 20px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    z-index: 120;
    @include mq('tablet', max) {
      border-radius: 12px;
      padding: 8px 10px;
      font-size: 14px;
      line-height: 18px;
    }
    @include mq('phablet', max) {
      padding: 8px;
    }
    &-icon {
      margin-left: 8px;
      width: auto;
      height: 26px;
      @include mq('tablet', max) {
        height: 18px
      }
    }
    &:not(:first-child) {
      margin-left: 16px;
    }
  }
}
.header-actions__toggle-sound {
  img {
    height: 36px;
    width: 36px;
    @include mq('tablet', max) {
      height: 20px;
      width: 20px;
    }
  }
}
.headerLinkActive {
  color: #ffa035;
}
.action-button {
  background-color: white;
  padding: 12px 16px;
  border-radius: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
button {
  color: #000000;
  display: block;
  position: relative;
  width: auto;
  text-align: center;
  font-size: 18px;
  line-height: 34px;
  top: 0px;
  background: transparent;
  border: none;
  padding: 0;
}

.dxp {
  margin-right: 10px;
}
.dxpLobbyIconBlack {
  margin-left: 8px;
  width: auto;
  height: 26px;
}


.nav-item-show {
  padding: 5px 1px;
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.disabled2 {
    pointer-events: none;
}

.menuActive {
  color:#fff!important;
}

.mono {
  font-family: monospace;
  font-size: 18px;
  font-weight: bold;
}

.connected button::after {
  background-color:#46d794!important
}

.navbar {
  position: fixed;
}
.dropicon {
 width: 40px;
 height: 40px;
}
.bars {
 width: 30px;
 height: 30px;
}


.dropicon {
    width: 40px;
    height: 40px;
    background: #fff;

    border-radius: 4px;
}

.bars {
  width: 20px;
    height: 20px;
    color: #fff;
    border: 0;
}

.actions li {
  padding: 5px 1px;
}

.nav-toggle {
  display: block;
}
.nav-item-hidden {
  display: none;
}
@media screen and (max-width: 992px) {
  .nav-item-show {
    position: absolute;
    top: 99%;
    left: 0;
    right: 0;
    display: block;
    background-color: #000;
    padding: 20px;
  }
  header.navbar nav {
    padding: 0 20px;
  }
}

@media screen and (min-width: 993px) {
  .nav-toggle {
    display: none;
  }
  .nav-item-hidden {
    display: flex;
  }
}

#spacer {
  height: 120px;
}
</style>
