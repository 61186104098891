export default {
  install: (Vue) => {
    let audio = new Audio(require("@/assets/sounds/roar.mp3"));
    let intro = new Audio(require("@/assets/sounds/intro.wav"));
    let loop = new Audio(require("@/assets/sounds/loop.wav"));
    let victory = new Audio(require("@/assets/sounds/victory.wav"));
    let lose = new Audio(require("@/assets/sounds/lose.mp3"));

    loop.addEventListener(
      "ended",
      function () {
        this.currentTime = 0;
        this.play();
      },
      false
    );
    Vue.prototype.$playRoar = () => {
      audio.play();
    };

    Vue.prototype.$playIntro = () => {
      intro.play();
    };
    Vue.prototype.$playLoop = () => {
      loop.play();
    };
    Vue.prototype.$stopLoop = () => {
      loop.pause();
      loop.currentTime = 0;
    };
    Vue.prototype.$playVictory = () => {
      victory.play();
    };
    Vue.prototype.$playLose = () => {
      lose.play();
    };

    Vue.prototype.$toggleAudio = (toggle) => {
      audio.muted = !toggle;
      intro.muted = !toggle;
      loop.muted = !toggle;
      victory.muted = !toggle;
      lose.muted = !toggle;
    };
  },
};
