<template>
  <transition name="modal-fade">
    <div class="modal-backdrop loader-container">
        <div class="main-loader"></div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Loader",
};
</script>

<style>
.main-loader {
    border: 10px solid #f3f3f3;
    border-top: 10px solid #ffa035;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}
.loader-container {
    background: rgba(0,0,0,.8)!important;
}
</style>