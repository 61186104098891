var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showModal && _vm.getCurrentFighter !== null)?_c('div',{staticClass:"fight-modal",class:{ active: _vm.showModal }},[_c('div',{staticClass:"fight-modal__content",class:{
      'fight-modal__content--round-success':
        _vm.currentModal === 4 && _vm.win === true,
      'fight-modal__content--round-fail': _vm.currentModal === 4 && !_vm.win,
      'fight-modal__content--battle-winner':
        _vm.currentModal === 5 && _vm.win === true,
      'fight-modal__content--battle-loser':
        _vm.currentModal === 5 && _vm.win === false,
      'fight-modal__content--battle-draw':
        _vm.currentModal === 5 && _vm.win === null,
    }},[(_vm.currentModal === 1)?_c('Fight'):_vm._e(),(_vm.currentModal === 2)?_c('FightFound'):_vm._e(),(_vm.currentModal === 3)?_c('FightRound'):_vm._e(),(_vm.currentModal === 4)?_c('FightResult'):_vm._e(),(_vm.currentModal === 5)?_c('FightWinner'):_vm._e()],1),(_vm.getIsPendingTransaction)?_c('div',{staticClass:"fight-overlay"},[_c('div',{staticClass:"main-loader loader"}),_c('p',[_vm._v("Confirming transaction…")])]):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }