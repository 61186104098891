import Vue from "vue";
import VueLazyload from "vue-lazyload";
import VueToast from "vue-toast-notification";
import AudioRoarPlugin from './plugins/audio';
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "vue-toast-notification/dist/theme-sugar.css";
const loadimage = require("./assets/images/loading.gif");

Vue.use(VueLazyload, {
  preLoad: 1.3,
  loading: loadimage,
  attempt: 1,
});
Vue.use(AudioRoarPlugin);
Vue.use(VueToast);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");