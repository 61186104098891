<template>
  <div class="fight-wrapper fight-wrapper--round">
    <div class="fight-wrapper__top">
      <h2 class="fight-wrapper__top-round">ROUND {{ currentRound() }}</h2>
    </div>
    <div class="fight-wrapper__content">
      <div class="fight-wrapper__battle fight-battle">
        <div class="fight-battle-player fight-battle-player--owner">
          <div
            class="fight-countdown"
            :class="leftTime <= 0 && 'fight-countdown--zero'"
            :style="`
              visibility: ${isMyturn() ? 'initial' : 'hidden'}
            `"
          >
            <img
              src="@/assets/images/icon-timer.svg"
              width="42"
              height="48"
              alt=""
            />
            <span>
              <span>{{ leftTime }}</span
              >&nbsp;
              <span class="fight-countdown__text">SECONDS LEFT</span>
              <span class="fight-countdown__text-mobile">SEC</span>
            </span>
          </div>
          <div class="fight-player">
            <div class="fighter">
              <div class="fighter__photo-container">
                <img
                  class="fighter__photo"
                  v-lazy="currentFighter.image"
                  alt=""
                />
              </div>
              <span class="fighter__name">{{ currentFighter.name }}</span>
              <div class="fighter__info">
                <span>LEVEL {{ currentFighter.level }}</span>
                <span>{{ currentFighter.health }}/100 HP</span>
              </div>
              <div class="fighter__progress">
                <span :style="`width: ${currentFighter.health}%`"></span>
              </div>
            </div>
          </div>
        </div>
        <div
          class="fight-actions"
          v-if="
            !(isFirstPlayer && attackCommitted()) &&
            !(!isFirstPlayer && revealCommitted())
          "
        >
          <button
            v-for="move of moves"
            :key="`${move.move}`"
            class="fight-action"
            :disabled="!isFirstPlayer && !attackCommitted()"
            @click="fightAction(move.move)"
          >
            <img
              class="fight-action__icon"
              :src="require('@/assets/images/' + move.icon)"
              alt=""
            />
            <span class="fight-action__text">{{ move.name }}!</span>
          </button>
        </div>
        <div class="fight-battle-player">
          <div class="fight-opponent">
            <div class="fighter">
              <div class="fighter__photo-container">
                <img class="fighter__photo" v-lazy="opponent.image" alt="" />
              </div>
              <span class="fighter__name">{{ opponent.name }}</span>
              <div class="fighter__info">
                <span>LEVEL {{ opponent.level }}</span>
                <span>{{ opponent.health }}/100 HP</span>
              </div>
              <div class="fighter__progress">
                <span :style="`width: ${opponent.health}%`"></span>
              </div>
            </div>
          </div>
          <div
            class="fight-countdown opponent-timer"
            :class="
              leftTime <= 0 && 'fight-countdown--zero timer-opponent--zero'
            "
            :style="`
              visibility: ${!isMyturn() ? 'initial' : 'hidden'}
            `"
          >
            <img
              src="@/assets/images/icon-timer.svg"
              width="42"
              height="48"
              alt=""
            />
            <span>
              <span>{{ leftTime }}</span
              >&nbsp;
              <span class="fight-countdown__text">SECONDS LEFT</span>
              <span class="fight-countdown__text-mobile">SEC</span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="fight-wrapper__bottom">
      <div class="fight-wrapper__bottom-message">
        <span>{{ getGameState() }}</span>
      </div>
      <button
        v-if="
          isFirstPlayer &&
          getRevealCommitted &&
          currentModal !== 1 &&
          currentModal !== 5
        "
        class="fight-wrapper__bottom-message fight-wrapper__bottom-message--knockout-btn"
        @click="reveal"
      >
        REVEAL
      </button>
      <button
        v-if="!isUserTurn && leftTime <= 0"
        class="fight-wrapper__bottom-message fight-wrapper__bottom-message--knockout-btn"
        @click="handleKnockout"
      >
        KNOCKOUT
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { ethers } from "ethers";
import { calculateGasMargin } from "../utils";

export default {
  name: "FightRound",
  data() {
    return {
      leftTime: this.duration,
      countdownInterval: null,
      winnerCandidate: 0,
    };
  },
  computed: {
    ...mapState([
      "currentFighter",
      "currentModal",
      "opponent",
      "isFirstPlayer",
      "moves",
      "move",
      "mineMove",
      "duration",
      "winnable",
      "moveSecret",
      "lastTime",
    ]),
    ...mapGetters({
      getAttackCommitted: "attackCommitted",
      getRevealCommitted: "revealCommitted",
    }),
    isUserTurn() {
      // Same functionality with isMyTurn() but this is more reactive and can be watched
      if (this.isFirstPlayer && !this.getAttackCommitted) {
        return true;
      }
      if (
        !this.isFirstPlayer &&
        this.getAttackCommitted &&
        !this.getRevealCommitted
      ) {
        return true;
      }
      if (this.isFirstPlayer && this.getRevealCommitted) {
        return true;
      }
      return false;
    },
    durationOut() {
      return this.winnerCandidate !== 0;
    },
  },
  methods: {
    ...mapGetters([
      "useFCContract",
      "currentRound",
      "attackCommitted",
      "revealCommitted",
    ]),
    ...mapActions([
      'setPendingTransaction',
    ]),
    reveal() {
      this.setPendingTransaction(true);
      const contract = this.useFCContract();
      console.log('contract', contract); // DEBUG
      const nftId = Number(this.currentFighter.nftId);
      console.log('nftId', nftId); // DEBUG
      const proof = ethers.utils.id(this.moveSecret);
      console.log(nftId, proof, this.mineMove, this.moveSecret)
      contract.estimateGas
        .attackReveal(nftId, this.mineMove, proof, {})
        .then((estimatedGas) => {
          contract
            .attackReveal(nftId, this.mineMove, proof, {
              gasLimit: this.calculateGasMargin(estimatedGas),
            })
            .then((res) => {
              res.wait().then(() => {
                this.setPendingTransaction(false);
                this.$toast.success("YOUR MOVE COMMITTED");
              });
            })
            .catch((error) => {
              this.setPendingTransaction(false);
              this.$toast.error(error.message);
            });
        })
        .catch((error) => {
          console.log(error)
          this.setPendingTransaction(false);
          this.$toast.error(error.message);
        });
    },
    handleKnockout() {
      this.setPendingTransaction(true);
      const contract = this.useFCContract();
      const nftId = Number(this.currentFighter.nftId);
      contract.knockout(nftId).then((res) => {
        res.wait().then(() => {
          this.setPendingTransaction(false);
          this.$toast.success("YOU WON BY KNOCKOUT!");
        });
      }).catch((error) => {
        this.setPendingTransaction(false);
      });
    },
    play() {
      this.$refs.roarAudio.play();
    },
    calculateGasMargin,
    fightAction(move) {
      console.log('fightAction', move);
      this.setPendingTransaction(true);
      const contract = this.useFCContract();
      const nftId = parseInt(this.currentFighter.nftId);

      if (contract) {
        if (this.isFirstPlayer) {
          this.$store.commit("setMoveSecret");
          this.$store.commit("SET_MY_MOVE", move);
          
          const proof = ethers.utils.id(this.moveSecret);
          const hash = ethers.utils.solidityKeccak256(
            ["bytes32", "uint8"],
            [proof, move]
          );
          console.log('attackCommit', { move: move, secret: this.moveSecret, proof: proof, hash: hash });
          contract.estimateGas
            .attackCommit(nftId, hash, {})
            .then((estimatedGas) => {
              contract
                .attackCommit(nftId, hash, {
                  gasLimit: this.calculateGasMargin(estimatedGas),
                })
                .then((res) => {
                  res.wait().then(() => {
                    // this.$toast.success("YOUR MOVE COMMITTED");
                    this.setPendingTransaction(false);
                    clearTimeout(this.countdownInterval);
                    this.startCounting();
                  });
                })
                .catch((error) => {
                  this.setPendingTransaction(false);
                  this.$toast.error(error.message);
                });
            })
            .catch((error) => {
              this.setPendingTransaction(false);
              this.$toast.error(error.message);
            });
        } else {
          contract.estimateGas
            .attack(nftId, move, {})
            .then((estimatedGas) => {
              contract
                .attack(nftId, move, {
                  gasLimit: this.calculateGasMargin(estimatedGas),
                })
                .then((res) => {
                  res.wait().then(() => {
                    // this.$toast.success("YOUR MOVE REVEALED");
                    this.setPendingTransaction(false);
                  });
                })
                .catch((error) => {
                  this.setPendingTransaction(false);
                  this.$toast.error(error.message);
                });
            })
            .catch((error) => {
              this.setPendingTransaction(false);
              this.$toast.error(error.message);
            });
        }
      } else {
        this.setPendingTransaction(false);
      }
    },
    getGameState() {
      if (this.leftTime <= 0) {
        if (
          (this.isFirstPlayer && !this.attackCommitted()) ||
          (!this.isFirstPlayer &&
            this.attackCommitted() &&
            !this.revealCommitted()) ||
          (this.isFirstPlayer && this.revealCommitted())
        ) {
          return "TIME OUT. YOU CAN BE KNOCKED OUT!";
        }
      }
      if (!this.isFirstPlayer && this.revealCommitted()) {
        return "WAITING ON OPPONENT...";
      }
      if (
        this.isFirstPlayer &&
        this.attackCommitted() &&
        !this.revealCommitted()
      ) {
        return "WAITING ON OPPONENT...";
      }

      if (this.isFirstPlayer && this.revealCommitted()) {
        return "REVEAL YOUR MOVE";
      }

      if (this.isFirstPlayer || this.attackCommitted()) {
        return "CHOOSE YOUR MOVE";
      } else {
        return `WAITING FOR OPPONENT'S MOVE`;
      }
    },
    isMyturn() {
      if (this.isFirstPlayer && !this.attackCommitted()) {
        return true;
      }
      if (
        !this.isFirstPlayer &&
        this.attackCommitted() &&
        !this.revealCommitted()
      ) {
        return true;
      }
      if (this.isFirstPlayer && this.revealCommitted()) {
        return true;
      }
      return false;
    },
    /*
    async checkWinable() {
      try {
        const contract = this.useFCContract();
        const nftId = parseInt(this.currentFighter.nftId);
        console.log('check winable', nftId);
        this.winnerCandidate = Number(await contract.getWinnerCandidate(nftId));
        console.log('winner candidtate', this.winnerCandidate)
        this.$store.commit("SET_WINABLE", this.winnerCandidate === nftId);
      } catch (err) {
        console.log(err);
      }
    },
    */
    async startCounting() {
      this.$store.commit("SET_WINNABLE", false);
      this.leftTime =
        this.lastTime + this.duration - Math.floor(Date.now() / 1000);
      console.log('LEFT TIME', this.leftTime);
      if (this.leftTime > 0) {
        this.countdownInterval = setInterval(() => {
          this.leftTime--;
          if (this.leftTime === 0) {
            console.log('TIME FINISHED');
            clearTimeout(this.countdownInterval);
            if (!this.isMyturn()) {
              this.$store.commit("SET_WINNABLE", true);
            }
          }
        }, 1000);
      } else {
        this.leftTime = 0;
        if (!this.isMyturn()) {
          this.$store.commit("SET_WINNABLE", true);
        }
      }
    },
  },
  watch: {
    lastTime() {
      clearTimeout(this.countdownInterval);
      this.startCounting();
    },
    isUserTurn() {
      if (this.isUserTurn) {
        this.$playLoop();
        this.$playRoar();
      }
    },
  },
  mounted() {
    this.startCounting();
    if (this.isUserTurn) {
      this.$playLoop();
      this.$playRoar();
    }
  },
  unmounted() {
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
    }
  },
};
</script>

<style lang="scss" scoped>
[disabled="disabled"] {
  opacity: 0.6;
}
.fight-battle-player {
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  @include mq("tablet-small", max) {
  }
}
.fight-countdown {
  margin-bottom: 32px;
  border: 4px solid #364207;
  border-left: 0;
  border-radius: 0px 20px 20px 0px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 16px 40px;
  font-family: Dinosaur;
  font-size: 24px;
  line-height: 48px;
  color: #364207;
  background: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
  white-space: nowrap;
  > span {
    width: 255px;
    text-align: right;
    @include mq("desktop", max) {
      font-size: 20px;
    }
    @include mq("tablet", max) {
      width: 120px;
      font-size: 18px;
    }
    @include mq("tablet-small", max) {
      font-size: 16px;
      padding-left: 8px;
      width: auto;
    }
  }
  @include mq("tablet-wide", max) {
    margin-bottom: 24px;
    padding: 16px 20px;
  }
  @include mq("tablet", max) {
    margin-bottom: 24px;
  }
  @include mq("tablet-small", max) {
    padding: 4px 16px;
    img {
      width: 30px;
      height: 35px;
    }
  }
  @include mq("phablet", max) {
    position: absolute;
    top: -70px;
  }
  &--zero {
    border: 4px solid white;
    border-left: 0px;
    background: #df1a1a;
    color: white;
  }
  &--hidden {
    visibility: hidden;
  }
  &.timer-opponent--zero {
    border-left: 4px solid;
    border-right: 0px;
  }
  &.opponent-timer {
    border-radius: 20px 0px 0px 20px;
    margin-top: 32px;
    margin-bottom: 0px;
    border-right: 0px;
    border-left: 4px solid;
    @include mq("tablet-wide", max) {
      margin-top: 24px;
    }
    @include mq("phablet", max) {
      margin-top: 0;
    }
  }
}
.fight-player {
  align-self: flex-end;
  @include mq("tablet-small", max) {
    align-self: center;
  }
  @include mq("phablet", max) {
    align-self: flex-start;
    padding: 8px 0;
  }
}
.fight-opponent {
  align-self: flex-start;
  @include mq("tablet-small", max) {
    align-self: center;
  }
  @include mq("phablet", max) {
    align-self: flex-end;
    padding: 8px 0;
  }
}
.fight-player,
.fight-opponent {
  max-width: 280px;
  @include mq("tablet-wide", max) {
    max-width: 240px;
  }
  @include mq("tablet", max) {
    max-width: 190px;
  }
  @include mq("phablet", max) {
    max-width: 100%;
  }
}
.fight-player__move--fail::before,
.fight-opponent__move--fail::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #df1a1a 39.06%,
    rgba(97, 15, 15, 0) 100%
  );
  opacity: 0.75;
  z-index: -1;
}
.fight-action__text {
  margin-bottom: 10px;
  font-family: Dinosaur;
  font-size: 16px;
  line-height: 22px;
  color: #fff;
}
.fight-actions {
  margin-top: 40px;
  @include mq("phablet", max) {
    margin-top: 24px;
  }
}
.fight-action {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border: 4px solid #fff;
  border-radius: 24px;
  width: 120px;
  height: 120px;
  text-align: center;
  background: #ffa035;
  box-shadow: 0px 4px 0px #c27e0f;
  align-items: center;
  @include mq("phablet", max) {
    width: 76px;
    height: 76px;
    border-radius: 12px;
  }
}
.fight-action:not(:last-child) {
  margin-bottom: 24px;
  @include mq("tablet-wide", max) {
    margin-bottom: 0;
  }
}
.fight-action__icon {
  margin-bottom: 10px;
  display: block;
  width: 100%;
  height: 64px;
  object-fit: contain;
  object-position: center;

  @include mq("phablet", max) {
    height: 38px;
    margin-bottom: 6px;
  }
}
.fight-action__text {
  margin-bottom: 10px;
  font-family: Dinosaur;
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  @include mq("phablet", max) {
    font-size: 10px;
    line-height: 14px;
    white-space: nowrap;
    margin-bottom: 0;
  }
}
</style>
