<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div
        class="modal manage-modal"
        role="dialog"
        aria-labelledby="Manage Modal"
      >
        <header class="modal-header" id="modalTitle">
          <slot name="header"> Manage Dino </slot>
          <button
            type="button"
            class="btn-close"
            @click="closeManageModal"
            aria-label="Close modal"
          >
            x
          </button>
        </header>
        <section
          class="modal-body"
          id="modalDescription"
          v-if="currentManaged == null"
        >
          <slot name="body"> Loading... </slot>
        </section>
        <section class="modal-body" id="modalDescription" v-else>
          <slot name="body">
            <template v-if="currentManaged.winsNeeded > 0">
              <div class="level-text">
                <strong>{{ currentManaged.name }}</strong> needs<br />{{
                  currentManaged.winsNeeded
                }}
                more win{{ currentManaged.winsNeeded == 1 ? "" : "s" }} before
                they can level up!
              </div>
              <button class="modalButton" disabled="true">
                LEVEL UP ({{ currentManaged.levelUpCost }} DXP)
              </button>
            </template>

            <template v-else>
              <div class="level-text">
                <strong>{{ currentManaged.name }}</strong> is ready for
                <strong>level {{ currentManaged.level + 1 }}</strong
                >!
              </div>
              <button
                class="modalButton"
                @click="levelUp(currentManaged.nftId)"
                :disabled="myDXP < currentManaged.levelUpCost"
              >
                LEVEL UP ({{ currentManaged.levelUpCost }} DXP)
              </button>
            </template>
            <hr />
            <input
              v-model="newName"
              type="text"
              @input="checkName"
              class="nameInput"
              placeholder="New Name"
            />
            <div v-if="nameTaken" class="name-taken">Name taken! :(</div>
            <button
              class="modalButton"
              @click="setName()"
              :disabled="!canSetName"
            >
              RENAME ({{ namingCost }} DXP)
            </button>
            <div class="name-status"></div>
          </slot>
        </section>
      </div>
    </div>
  </transition>
</template>

<script>
import { ethers } from "ethers";
import { mapMutations, mapGetters, mapState } from "vuex";
import { calculateGasMargin } from "../utils";

export default {
  name: "ManageModal",
  computed: {
    ...mapState(["currentManaged", "myDXP"]),
    canSetName: function () {
      if (this.newName.length < 3) return false;
      if (this.newName.length > 22) return false;
      if (this.nameTaken) return false;
      if (this.myDXP < this.namingCost) return false;

      return true;
    },
  },
  data() {
    return {
      newName: "",
      nameTaken: false,
      namingCost: 1000,
      //   connectors,
    };
  },
  async mounted() {
    const contract = this.useDNSContract();
    if (contract) {
      this.namingCost = Number(
        ethers.utils.formatUnits(await contract.namingCost())
      );
    }
  },
  methods: {
    ...mapGetters(["useFCContract", "useDNSContract"]),
    ...mapMutations(["closeManageModal", "getMyDinos"]),
    calculateGasMargin,
    close() {
      this.$emit("close");
    },
    levelUp() {
      const contract = this.useFCContract();
      const nftId = parseInt(this.currentManaged.nftId);
      if (contract) {
        contract.estimateGas
          .upgradeLevel(nftId, {})
          .then((estimatedGas) => {
            contract
              .upgradeLevel(nftId, {
                gasLimit: this.calculateGasMargin(estimatedGas),
              })
              .then((res) => {
                res.wait().then(() => {
                  this.getMyDinos();
                  this.closeManageModal();
                  this.$toast.success("YOUR LEVEL IS UPGRADED");
                });
              })
              .catch((error) => {
                this.$toast.error(error.message);
              });
          })
          .catch((error) => {
            this.$toast.error(error.message);
          });
      }
    },
    setName() {
      this.newName = this.newName.toLowerCase().replace(/[^a-z0-9]/gi, "");
      const nftId = parseInt(this.currentManaged.nftId);
      const nameB32 = ethers.utils.formatBytes32String(this.newName);
      const contract = this.useDNSContract();
      if (contract) {
        contract.estimateGas
          .setName(nftId, nameB32)
          .then((estimatedGas) => {
            contract
              .setName(nftId, nameB32, {
                gasLimit: this.calculateGasMargin(estimatedGas),
              })
              .then((res) => {
                res.wait().then(() => {
                  this.newName = "";
                  this.getMyDinos();
                  this.closeManageModal();
                  this.$toast.success("YOUR NAME IS UPDATED");
                });
              })
              .catch((error) => {
                this.$toast.error(error.message);
              });
          })
          .catch((error) => {
            this.$toast.error(error.message);
          });
      }
    },
    async checkName() {
      this.newName = this.newName.toLowerCase().replace(/[^a-z0-9]/gi, "");
      const nameB32 = ethers.utils.formatBytes32String(this.newName);
      const contract = this.useDNSContract();
      if (!contract) {
        throw new Error("DNS Contract not initialized");
      }

      let tokenId = await contract.DNS(nameB32);
      tokenId = tokenId.toNumber();

      this.nameTaken = tokenId !== 0;
    },
  },
};
</script>

<style lang="scss">
[disabled="disabled"] {
  opacity: 0.6;
  cursor: not-allowed;
}

.modal {
  background: #ffffff;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  border-radius: 30px;
  padding: 18px;
}
.manage-modal {
  width: 400px;
}

hr {
  border: 2px solid black;
  margin: 30px;
}

.modalButton {
  background: #ffa035;
  box-shadow: 0px 2.32136px 0px #d0410a;
  border-radius: 9px;
  color: white;
  font-family: "Dinosaur";
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  margin: 10px auto;
  display: block;
  padding: 4px 12px;
}
.nameInput {
  border: 2px solid #000;
  padding: 10px;
  font-size: 16px;
  font-family: "Dinosaur";
  outline: none;
  margin: 0 auto;
  display: block;
  @include mq('phone', max) {
    width: calc(100% - 20px);
  }
}

.level-text {
  width: 200px;
  margin: 0 auto;
  text-align: center;
}

.name-taken {
  color: #db1414;
  text-align: center;
}
</style>
