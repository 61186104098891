<template>
  <div class="fight-wrapper fight-search">
    <div class="fight-wrapper__top">
      <button class="fight-search__leave-btn" @click="leave()">
        LEAVE LOBBY
      </button>
    </div>
    <div class="fight-wrapper__content">
      <div class="fight-wrapper__players">
        <div class="fighter fight-player">
          <img class="fighter__photo" v-lazy="currentFighter.image" alt="" />
          <span class="fighter__name">{{ currentFighter.name }}</span>
          <div class="fighter__info">
            <span>LEVEL {{ currentFighter.level }}</span>
            <span>{{ currentFighter.health }}/100 HP</span>
          </div>
          <div class="fighter__progress">
            <span :style="`width: ${currentFighter.health}%`"></span>
          </div>
        </div>
        <div class="fight-search__loader"></div>
        <div class="fighter">
          <img class="fighter__photo" src="@/assets/avatars/AvatarUnknown.jpg" />
          <span class="fighter__name">???</span>
          <div class="fighter__info">
            <span>LEVEL ?</span>
            <span>?/? HP</span>
          </div>
          <div class="fighter__progress">
            <span style="width: 100%"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="fight-wrapper__bottom">
      <div class="fight-wrapper__bottom-message">
        <span>LOOKING FOR AN OPPONENT...</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { calculateGasMargin } from "../utils";

export default {
  name: "TheFight",
  computed: {
    ...mapState(["currentFighter"]),
    getCurrentDino() {
      return this.$store.getters.getCurrentModal;
    },
  },
  methods: {
    ...mapGetters(["useFCContract"]),
    calculateGasMargin,
    leave() {
      const contract = this.useFCContract();
      const nftId = Number(this.currentFighter.nftId);
      contract.estimateGas
        .leaveLobby(nftId, {})
        .then((estimatedGas) => {
          contract
            .leaveLobby(nftId, {
              gasLimit: this.calculateGasMargin(estimatedGas),
            })
            .then((res) => {
              res.wait().then(() => {
                this.$toast.success("YOU LEFT LOBBY");
                this.$store.commit("SET_MODAL", false);
              });
            })
            .catch((error) => {
              this.$toast.error(error.message);
            });
        })
        .catch((error) => {
          this.$toast.error(error.message);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.fight-search__leave-btn {
  margin-left: 96px;

  border-radius: 16.6667px;
  padding: 10px 28px;
  font-family: Dinosaur;
  font-size: 21px;
  line-height: 30px;
  background: #d0410a;
  box-shadow: 0px 2.77778px 0px #6e1f00;
  color: #fff;

  @include mq('tablet-wide', max) {
    margin-left: 24px;
    font-size: 16px;
    padding: 4px 28px;
  }
  @include mq('tablet-small', max) {
    margin-left: 16px;
  }
}

.fight-search__loader {
  height: 128px;
  width: 128px;
  background: url("../assets/images/loader.png") no-repeat center / cover;
  animation: rotating 2s linear infinite;
  @include mq('tablet', max) {
    height: 90px;
    width: 90px;
  }
  @include mq('tablet-small', max) {
    height: 128px;
    width: 128px;
    position: absolute;
    top: calc(50% - 64px);
    left: calc(50% - 64px);
    z-index: 1;
  }
}
</style>
