var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fight-wrapper fight-wrapper--result"},[_c('div',{staticClass:"fight-wrapper__top"},[_c('h2',{staticClass:"fight-wrapper__top-round"},[_vm._v("ROUND "+_vm._s(_vm.currentRound() - 1))])]),_c('div',{staticClass:"fight-layout",class:{
      'fight-layout--success': _vm.win === true,
      'fight-layout--fail': !_vm.win,
    }},[_c('div',{staticClass:"fight-battle"},[_c('div',{staticClass:"fight-battle-player"},[_c('div',{staticClass:"fight-player fight-player--move"},[_c('div',{staticClass:"fighter"},[_c('div',{staticClass:"fighter__photo-container"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.currentFighter.image),expression:"currentFighter.image"}],staticClass:"fighter__photo",attrs:{"alt":""}})]),_c('span',{staticClass:"fighter__name"},[_vm._v(_vm._s(_vm.currentFighter.name))]),_c('div',{staticClass:"fighter__info"},[_c('span',[_vm._v("LEVEL "+_vm._s(_vm.currentFighter.level))]),_c('span',[_vm._v(_vm._s(_vm.currentFighter.health)+"/100 HP")])]),_c('div',{staticClass:"fighter__progress"},[_c('span',{style:(("width: " + (_vm.currentFighter.health) + "%"))})])]),_c('img',{staticClass:"fight-player__hit",attrs:{"src":require("@/assets/images/fight-hit.png"),"alt":""}}),(_vm.myMove && _vm.myMove !== 0)?_c('div',{staticClass:"fight-player__move",class:{
              'fight-player__move--success': _vm.win === true,
              'fight-player__move--fail': !_vm.win,
            }},[_c('img',{attrs:{"src":_vm.getAttactImage(_vm.myMove, _vm.win),"alt":""}}),_c('span',[_vm._v(_vm._s(_vm.getAttackString(_vm.myMove))+"!")])]):_vm._e(),(!_vm.finished)?[(!_vm.win)?_c('div',{staticClass:"fight-player__damage"},[_vm._v(" "+_vm._s(_vm.win === false ? -35 : -15)+" ")]):_vm._e()]:_vm._e()],2)]),_c('div',{staticClass:"fight-battle-opponent"},[_c('div',{staticClass:"fight-opponent fight-opponent--move"},[_c('div',{staticClass:"fighter"},[_c('div',{staticClass:"fighter__photo-container"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.opponent.image),expression:"opponent.image"}],staticClass:"fighter__photo",attrs:{"alt":""}})]),_c('span',{staticClass:"fighter__name"},[_vm._v(_vm._s(_vm.opponent.name))]),_c('div',{staticClass:"fighter__info"},[_c('span',[_vm._v("LEVEL "+_vm._s(_vm.opponent.level))]),_c('span',[_vm._v(_vm._s(_vm.opponent.health)+"/100 HP")])]),_c('div',{staticClass:"fighter__progress"},[_c('span',{style:(("width: " + (_vm.opponent.health) + "%"))})])]),(_vm.opponentMove && _vm.opponentMove !== 0)?_c('div',{staticClass:"fight-opponent__move",class:{
              'fight-player__move--success': _vm.win === false,
              'fight-player__move--fail': _vm.win === true || _vm.win === null,
            }},[_c('img',{attrs:{"src":_vm.getAttactImage(_vm.opponentMove, _vm.win === null ? !!_vm.win : !_vm.win),"alt":""}}),_c('span',[_vm._v(_vm._s(_vm.getAttackString(_vm.opponentMove))+"!")])]):_vm._e(),(!_vm.finished)?[(_vm.win === true || _vm.win === null)?_c('div',{staticClass:"fight-opponent__damage"},[_vm._v(" "+_vm._s(_vm.win === true ? -35 : -15)+" ")]):_vm._e()]:_vm._e()],2)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }