<template>
  <div
    v-if="showModal && getCurrentFighter !== null"
    class="fight-modal"
    :class="{ active: showModal }"
  >
    <div
      class="fight-modal__content"
      :class="{
        'fight-modal__content--round-success':
          currentModal === 4 && win === true,
        'fight-modal__content--round-fail': currentModal === 4 && !win,
        'fight-modal__content--battle-winner':
          currentModal === 5 && win === true,
        'fight-modal__content--battle-loser':
          currentModal === 5 && win === false,
        'fight-modal__content--battle-draw':
          currentModal === 5 && win === null,
      }"
    >
      <Fight v-if="currentModal === 1" />
      <FightFound v-if="currentModal === 2" />
      <FightRound v-if="currentModal === 3" />
      <FightResult v-if="currentModal === 4" />
      <FightWinner v-if="currentModal === 5" />
    </div>
    <div v-if="getIsPendingTransaction" class="fight-overlay">
      <div class="main-loader loader"></div>
      <p>Confirming transaction…</p>
    </div>
  </div>
</template>

<script>
import { ethers } from "ethers";
import { mapState, mapGetters } from "vuex";
import Fight from "./Fight.vue";
import FightFound from "./FightFound.vue";
import FightRound from "./FightRound.vue";
import FightResult from "./FightResult.vue";
import FightWinner from "./FightWinner.vue";
import { calculateGasMargin } from "../utils";

export default {
  name: "FightModal",
  components: {
    Fight,
    FightFound,
    FightRound,
    FightResult,
    FightWinner,
  },
  mounted() {
    this.windowHeight = window.innerHeight;
  },
  computed: {
    ...mapState([
      "currentFighter",
      "currentModal",
      "winnable",
      "isFirstPlayer",
      "lastTime",
      "duration",
      "attacked",
      "mineMove",
      "moveSecret",
      "win",
    ]),
    ...mapGetters(['getIsPendingTransaction']),
    showModal() {
      return this.$store.getters.getModal;
    },
    getCurrentFighter() {
      return this.$store.getters.getCurrentModal;
    },
    zoom() {
      return {
        zoom: this.windowHeight > 1000 ? 1 : (this.windowHeight - 100) / 900,
      };
    },
  },
  methods: {
    ...mapGetters(["useFCContract", "revealCommitted"]),
    calculateGasMargin,
    inDuration() {
      return this.lastTime + this.duration > Math.floor(Date.now() / 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.fight-overlay {
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.7);
  flex-direction: column;
  color: white;
  text-align: center;
  .loader {
    transform: scale(1);
  }
}
.fight-modal {
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  overflow: hidden;
  z-index: 999;
  flex-direction: column;
  transform: scale(0);
  transition: all 0.3s linear;
  &.active {
    visibility: visible;
    transform: scale(1);
  }
  &__notice-item {
    margin-bottom: 10px;
  }
  &__content {
    border: 4px solid #ffffff;
    border-radius: 20px;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    background: url(../assets/images/fight-background.png) no-repeat center /
      cover;
    width: 990px;
    height: calc(100vh - 88px);
    min-width: 312px;
    &--round-success {
      border-color: #72cb34;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: radial-gradient(
          50% 50% at 50% 50%,
          rgba(100, 203, 52, 0) 0%,
          rgba(100, 203, 52, 0.0975) 70.83%,
          rgba(100, 203, 52, 0.75) 88.02%,
          rgba(58, 224, 0, 0.75) 100%
        );
        opacity: 0.25;
        z-index: 1;
      }
    }
    &--round-fail {
      border-color: #df1a1a;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: radial-gradient(
          50% 50% at 50% 50%,
          rgba(142, 0, 0, 0) 0%,
          rgba(147, 1, 1, 0.13) 60.94%,
          #b30707 100%
        );
        opacity: 0.25;
        z-index: 1;
      }
    }
    &--battle-winner {
      border-color: #feca35;
    }
    &--battle-loser {
      border-color: #df1a1a;
    }
    &--battle-draw {
      border-color: #ffffff;
    }
    @media (min-height: 900px) {
      height: 840px;
    }
    @include mq("tablet-wide", max) {
      width: calc(100% - 48px);
    }
  }
}
</style>
