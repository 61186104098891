<template>
  <div id="lobby" class="lobby container">
    <span class="lobbyTextTitle">MY FIGHTERS</span>
    <div class="lobbyTableWrapper">
    <table class="fighters-table" v-if="myDinos.length > 0">
      <thead>
        <tr class="fighters-table__header">
          <th class="fighters-table__header-item"></th>
          <th class="fighters-table__header-item"></th>
          <th class="fighters-table__header-item">LEVEL</th>
          <th class="fighters-table__header-item">HEALTH</th>
          <th class="fighters-table__header-item">WINS</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) of myDinos"
          :key="index"
          class="fighters-table__row"
        >
          <td class="fighters-table__item">
            <div class="mf-item">
              <div class="mf-avatar">
                <img class="mf-avater__image" v-lazy="item.image" alt="" />
              </div>
              <div class="mf-item__dino-info">
                <p class="mf-item__name">{{ item.name }}</p>
                <p class="mf-item__level">LEVEL {{ item.level }}</p>
              </div>
              <div class="mf-item__dino-details">
                <p class="mf-item__win">Win: {{ item.wins }}</p>
                <p class="mf-item__health">{{ item.health }}/100 HP</p>
              </div>
              <div class="mf-item__dino-buttons">
                <button class="mf-manage__button" @click="manageFighter(item)">
                  MANAGE
                </button>
              </div>
            </div>
          </td>
          <td class="fighters-table__item">{{ item.name }}</td>
          <td class="fighters-table__item mf-level">{{ item.level }}</td>
          <td class="fighters-table__item mf-health">{{ item.health }}/100</td>
          <td class="fighters-table__item mf-wins">{{ item.wins }}</td>
          <td class="fighters-table__item mf-manage">
            <button class="mf-manage__button" @click="manageFighter(item)">
              MANAGE
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    </div>
    <div class="footerLogo">
      <img src="@/assets/images/Logo.png" class="logo" />
    </div>
  </div>
</template>

<script>
import { ethers } from "ethers";
import { mapState, mapGetters, mapMutations } from "vuex";

export default {
  name: "MyFighters",
  computed: mapState(["myDinos", "currentManaged", "isManageModalOpen"]),
  methods: {
    ...mapGetters(["useFCContract"]),
    ...mapMutations(["openManageModal"]),
    async manageFighter(data) {
      this.$store.commit("SET_CURRENT_MANAGED", null);
      const contract = this.useFCContract();
      if (!contract) {
        throw new Error("FC Contract not initialized");
      }

      let levelTo = data.level + 1;

      let levelUpCost = await contract.calculateUpgradeDXP(levelTo);
      data.levelUpCost = ethers.utils.formatEther(levelUpCost);

      let wins = await contract.wins(data.nftId);
      let lastWins = await contract.lastWins(data.nftId);
      let winsRequired = Math.floor(levelTo / 7) + 2;
      let winsSince = wins - lastWins;
      let winsNeeded =
        winsRequired - winsSince > 0 ? winsRequired - winsSince : 0;

      data.winsNeeded = winsNeeded;

      this.openManageModal();
      this.$store.commit("SET_CURRENT_MANAGED", data);
    },
  },
};
</script>

<style lang="scss" scoped>
.lobby {
  @include mq('tablet-wide', min) {
    max-width: 953px;
  }
  @media (max-width: 575px) {
    width: calc(100% - 24px);
    margin: 0;
  }
}
.lobbyContainer {
  margin: 0 auto;
  width: 952px;
  width: 100%;
  @include mq('tablet', max) {
    width: calc(100% - 24px);
    padding: 0 12px;
  }
}
.lobbyTableWrapper {
  max-width: 100%;
  width: 100%;
  overflow-x: auto;
}
.lobbyContainer::after {
  content: "";
  clear: both;
}
.lobbyTextTitle {
  display: block;
  font-family: Dinosaur;
  font-size: 60px;
  line-height: 64px;
  color: #fff;
  @include mq('tablet-wide', max) {
    font-size: 50px;
  }
  @include mq('tablet', max) {
    font-size: 40px;
  }
}
.fighters-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 16px;
  margin-top: 20px;
  @include mq('tablet', max) {
    margin-top: 0;
  }
}
.fighters-table__row {
  background: white;
  font-size: 15px;
  color: black;
  line-height: 50px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  td {
    padding: 9px 18px;
    @include mq('tablet', max) {
      border-radius: 10px;
      padding: 16px;
    }
  }
}
.fighters-table__item:not(:first-child),
.fighters-table__header-item:not(:first-child) {
  @include mq('tablet', max) {
    display: none;
  }
}
.fighters-table__header {
  font-size: 15px;
  color: white;
  white-space: nowrap;
}
.mf-avatar {
  width: 64px;
  height: 64px;
  border: 3px solid black;
  border-radius: 50%;
  background-color: white;
  overflow: hidden;
  margin: 0 auto;
}
.mf-level {
  text-align: center;
}
.mf-avater__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.mf-wins {
  width: 42px;
  text-align: center;
}
.mf-health {
  width: 62px;
  text-align: center;
}
.mfDXPperWin {
  text-align: center;
}
.mf-manage {
  text-align: right;
}
.mf-item {
  &__dino-info,
  &__dino-details,
  &__dino-buttons {
    display: none;
  }
  @include mq('tablet', max) {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .mf-avatar {
      height: 50px;
      width: 50px;
      margin-left: 0;
      margin-right: 16px;
    }
    .mf-item__dino-info {
      display: initial;
      width: 100%;
      flex: 1;
    }
    .mf-item__dino-details {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin: 16px 0;
      p {
        font-size: 18px;
        line-height: 20px;
      }
    }
    .mf-item__dino-buttons {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      .mf-manage__button {
        margin-right: 0;
      }
    }
    p {
      margin: 0;
    }
    .mf-item__name {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 4px;
    }
    .mf-item__level {
      font-size: 18px;
      line-height: 20px;
    }
  }
}
.mfLvlButton {
  background: #53bc42;
  box-shadow: 0px 2.32136px 0px #53bc42;
  border-radius: 9px;
  font-family: "Dinosaur";
  font-size: 16px;
  color: white;
}
.mf-manage__button {
  margin-left: auto;
  background: #ffa035;
  box-shadow: 0px 2.32136px 0px #d0410a;
  border-radius: 9px;
  color: white;
  font-family: "Dinosaur";
  font-size: 16px;
  padding: 4px 12px;
  line-height: 30px;
  text-align: center;
  margin-right: 10px;
}

</style>
