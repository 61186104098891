import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import abi from "../constants/abi.json";
import dxpAbi from "../constants/dxp.abi.json";
import fcAbi from "../constants/fc.abi.json";
import dnsAbi from "../constants/dns.abi.json";
import { ZERO_COMMIT } from "@/utils";
import { v4 as uuidv4 } from "uuid";
import { ethers } from "ethers";

Vue.use(Vuex);

const ChainParams = {
  chainId: process.env.VUE_APP_CHAIN_ID_HEX,
  chainName: process.env.VUE_APP_CHAIN_ID_NUMBER,
  rpcUrls: [process.env.VUE_APP_RPCURL],
  blockExplorerUrls: [process.env.VUE_APP_EXPURL],
};

const contractAddress = process.env.VUE_APP_DINO_CONTRACT;
const fcContract = process.env.VUE_APP_FC_CONTRACT;
const dnsContract = process.env.VUE_APP_DNS_CONTRACT;
const dxpContract = process.env.VUE_APP_DXP_CONTRACT;

const moves = [
  {
    name: "CLAW",
    move: 1,
    icon: "icon-claw.svg",
    winIcon: "move-claw-win.png",
    loseIcon: "move-claw-lose.png",
  },
  {
    name: "BITE",
    move: 2,
    icon: "icon-bite.svg",
    winIcon: "move-bite-win.png",
    loseIcon: "move-bite-lose.png",
  },
  {
    name: "TAIL-WHIP",
    move: 3,
    icon: "icon-tail-whip.svg",
    winIcon: "move-tail-win.png",
    loseIcon: "move-tail-lose.png",
  },
];

export default new Vuex.Store({
  state: {
    account: null,
    allNFT: [],
    gameId: 0,
    chainId: null,
    currentFighter: null,
    currentManaged: null,
    currentModal: 1,
    FullMeta: "https://nft.defidinos.com/metadata.json",
    game: {},
    isFirstPlayer: false,
    isLoading: false,
    isModalOpen: false,
    isManageModalOpen: false,
    modal: 0,
    myDXP: 0,
    myDinos: [],
    fetchingUserData: false,
    mineMove: localStorage.getItem("mineMove"),
    moves: moves,
    opponent: null,
    signer: null,
    win: null,
    duration: 190,
    winnable: false,
    finished: false,
    myMove: 0,
    opponentMove: 0,
    moveSecret: localStorage.getItem("moveSecret"),
    lastTime: null,
    amountWinner: 0,
    amountLoser: 0,
    wrongChainId: false,
    pendingTransaction: false,
    lastBlock: 0
  },
  mutations: {
    SET_PENDING_TRANSACTION(state, payload) {
      state.pendingTransaction = payload;
    },
    SET_LOADING(state, data) {
      state.isLoading = data;
    },
    RESET(state) {
      state.mineMove = null;
      localStorage.setItem("mineMove", null);
    },
    SET_GAME(state, data) {
      state.game = data;
    },
    SET_MODAL(state, modal) {
      state.modal = modal;
    },
    SET_CURRENT_FIGHTER(state, data) {
      console.log("SET_CURRENT_FIGHTER", data);
      state.currentFighter = state.myDinos[data];
    },
    SET_CURRENT_MANAGED(state, data) {
      state.currentManaged = data;
    },
    SET_CURRENT_MODAL(state, data) {
      state.currentModal = data;
    },
    SET_MY_MOVE(state, data) {
      console.log("SET_MY_MOVE", data);
      state.mineMove = data;
      localStorage.setItem("mineMove", data);
    },
    SET_WINNER(state, loser) {
      state.win =
        loser === 0 || loser === null ? null : loser !== Number(state.currentFighter.nftId);
    },
    SET_MOVES(state, { firstMove, secondMove }) {
      if (state.isFirstPlayer) {
        state.myMove = firstMove;
        state.opponentMove = secondMove;
      } else {
        state.myMove = secondMove;
        state.opponentMove = firstMove;
      }
    },
    SET_WINNABLE(state, data) {
      state.winnable = data;
    },
    SET_WRONG_CHAIN_ID(state, isWrongChainId) {
      state.wrongChainId = isWrongChainId;
    },
    SET_ACCOUNT(state, accountAddress) {
      state.account = accountAddress;
    },
    openWalletModal(state) {
      state.isModalOpen = true;
    },
    closeWalletModal(state) {
      state.isModalOpen = false;
    },
    openManageModal(state) {
      state.isManageModalOpen = true;
    },
    closeManageModal(state) {
      state.isManageModalOpen = false;
    },
    async getAllNFT(state) {
      try {
        Vue.set(state, "fetchingUserData", true);
        await axios(state.FullMeta).then((data) => {
          state.allNFT = data.data;
          this.commit("getMyDinos");
          this.commit("getmyDXP");
        });
      } catch (error) {
        // error handling
      } finally {
        Vue.set(state, "fetchingUserData", false);
      }
    },
    async getMyDinos(state) {
      const contract = new ethers.Contract(contractAddress, abi, state.signer);
      const fc = new ethers.Contract(fcContract, fcAbi, state.signer);
      const healthPromises = [];
      const levelPromises = [];
      const winPromises = [];

      if (state.signer) {
        const nftMAP = (await contract.tokensOfOwner(state.account)).map(
          (nftId) => {
            return { nftId };
          }
        );
        if (nftMAP.length) {
          nftMAP.forEach(({ nftId }) => {
            healthPromises.push(fc.getHealth(nftId));
            winPromises.push(fc.wins(nftId));
            levelPromises.push(fc.level(nftId));
          });
          const healths = await Promise.all(healthPromises);
          const levels = await Promise.all(levelPromises);
          const wins = await Promise.all(winPromises);
          let myDinosItems = [];
          for (let index = 0; index < nftMAP.length; index++) {
            const { nftId } = nftMAP[index];
            if (state.allNFT[nftId]) {
              const attributes = state.allNFT[nftId].attributes;
              if (!attributes.length) continue;
              attributes.splice(0, 1);
              const points = attributes
                .map((bill) => parseFloat(bill.points || 0))
                .reduce((acc, bill) => bill + acc);
              const nftName = ethers.utils.parseBytes32String(
                await this.getters.useDNSContract.name(nftId)
              );
              const metaData = {
                id:
                  Date.now().toString(36) +
                  Math.random().toString(36).substring(2),
                name: nftName !== "" ? nftName : state.allNFT[nftId].name,
                nftId: nftId,
                image: state.allNFT[nftId].image,
                points: points,
                active: false,
                attributes: attributes.map((task) => {
                  return {
                    task,
                    id:
                      Date.now().toString(36) +
                      Math.random().toString(36).substring(2),
                  };
                }),
                health: parseInt(healths[index] / 1000),
                level:
                  parseInt(levels[index]) === 0 ? 1 : parseInt(levels[index]),
                wins: parseInt(wins[index]),
              };
              myDinosItems.push(metaData);
            }
          }
          state.myDinos = myDinosItems[0] !== false ? myDinosItems : [];
          this.commit("checkInFight");
        } else {
          state.myDinos = [];
        }
      } else {
        state.myDinos = [];
      }
    },
    async getHealth(state, { nftId }) {
      const contract = new ethers.Contract(fcContract, fcAbi, state.signer);
      return await contract.getHealth(parseInt(nftId));
    },
    async getmyDXP(state) {
      if (!state.signer) {
        state.myDXP = null;
        return;
      }
      const contract = new ethers.Contract(dxpContract, dxpAbi, state.signer);
      const balance = await contract.balanceOf(state.account);
      state.myDXP = Number(ethers.utils.formatUnits(balance));
    },
    async checkInFight(state) {
      const contract = new ethers.Contract(fcContract, fcAbi, state.signer);
      const lobbyDino = await contract.getLobby();
      const fighterId = parseInt(await contract.getFighter());
      if (!(fighterId === 10000 || fighterId === 0)) {
        state.currentFighter = state.myDinos.find(
          ({ nftId }) => parseInt(nftId) === fighterId
        );
        this.commit("getOpponentInGame");
        this.commit("SET_MODAL", true);
      } else if (lobbyDino.length > 0) {
        state.currentFighter = state.myDinos.find(
          ({ nftId }) => parseInt(nftId) === parseInt(lobbyDino[0])
        );
        if (state.currentFighter) {
          this.commit("SET_MODAL", true);
        }
      }
    },
    async getOpponentInGame(state) {
      const contract = this.getters.useFCContract;

      const game = await contract.getGameInfo(state.currentFighter.nftId);
      console.log(game);
      state.game = game;

      state.isFirstPlayer =
        Number(game.opponent0) === Number(state.currentFighter.nftId);

      if (Number(game.timestamp) !== 0) {
        const opponent = state.isFirstPlayer
          ? Number(game.opponent1)
          : Number(game.opponent0);
        const health = await contract.getHealth(opponent);
        const level = await contract.level(opponent);
        state.opponent = state.allNFT[opponent];
        state.opponent.nftId = opponent;
        state.opponent.health = parseInt(health / 1000);
        state.opponent.level = parseInt(level) === 0 ? 1 : parseInt(level);
        const nftName = ethers.utils.parseBytes32String(
          await this.getters.useDNSContract.name(opponent)
        );
        if (nftName !== "") state.opponent.name = nftName;
        state.lastTime = Number(game.timestamp);
        console.log("@@@@", state.lastTime);
        this.commit("SET_CURRENT_MODAL", 2);
      }
    },
    async getDuration(state) {
      const contract = new ethers.Contract(fcContract, fcAbi, state.signer);
      state.duration = Number(await contract.duration());
    },
    async gameFinished(state) {
      state.game = {};
      this.commit("SET_CURRENT_MODAL", 5);
    },
    async gameNextRound(state, data) {
      state.game = data;
      this.commit("SET_CURRENT_MODAL", 4);
    },
    async updateHealth(state) {
      const contract = new ethers.Contract(
        fcContract,
        fcAbi,
        webSocketProvider
      );
      if (!state.opponent.nftId) {
        const opponent = Number(
          await contract.currentOpponent(parseInt(state.currentFighter.nftId))
        );
        state.opponent.nftId = opponent;
      }
      state.opponent.health = Number(
        await contract.getHealth(state.opponent.nftId)
      );
      state.currentFighter.health = Number(
        await contract.getHealth(parseInt(state.currentFighter.nftId))
      );
    },
    setMoveSecret(state) {
      let secret = uuidv4();
      secret = secret.replace(/-/g, "");
      state.moveSecret = secret;
      localStorage.setItem("moveSecret", secret);
      console.log(`Secret Updated: ${secret}`)
    },
    async EventGameStarted(state, { game }) {
      if (!state.currentFighter) {
        console.log("No current fighter");
        return;
      }

      console.log(game, parseInt(game.opponent0), parseInt(game.opponent1), parseInt(state.currentFighter.nftId));

      if (
        parseInt(game.opponent0) !== parseInt(state.currentFighter.nftId) &&
        parseInt(game.opponent1) !== parseInt(state.currentFighter.nftId)
      )
        return;

      console.log("GameStarted");
      const contract = this.getters.useFCContract;

      state.game = game;
      const isMyDino =
        Number(state.currentFighter.nftId) === Number(game.opponent1);
      const opponent = isMyDino
        ? Number(game.opponent0)
        : Number(game.opponent1);
      const health = await contract.getHealth(opponent);
      const level = await contract.level(opponent);
      state.opponent = isMyDino
        ? state.allNFT[game.opponent0]
        : state.allNFT[game.opponent1];
      state.opponent.nftId = opponent;
      state.opponent.health = parseInt(health / 1000);
      state.opponent.level = parseInt(level) === 0 ? 1 : parseInt(level);
      const nftName = ethers.utils.parseBytes32String(
        await this.getters.useDNSContract.name(opponent)
      );
      if (nftName !== "") state.opponent.name = nftName;
      this.commit("SET_MODAL", true);
      this.commit("SET_CURRENT_MODAL", 2);
      state.isFirstPlayer = !isMyDino;
      state.lastTime = Number(game.timestamp);
    },
    async EventAttackCommitted(state, { game }) {
      if (!state.currentFighter) return;
      if (
        parseInt(game.opponent0) !== parseInt(state.currentFighter.nftId) &&
        parseInt(game.opponent1) !== parseInt(state.currentFighter.nftId)
      )
        return;

      console.log("AttackCommitted");

      state.game = game;
      state.lastTime = Number(game.timestamp);
    },
    async EventRevealCommitted(state, { game }) {
      if (!state.currentFighter) return;
      if (
        parseInt(game.opponent0) !== parseInt(state.currentFighter.nftId) &&
        parseInt(game.opponent1) !== parseInt(state.currentFighter.nftId)
      )
        return;

      console.log("RevealCommitted", game);

      state.game = game;
      state.lastTime = Number(game.timestamp);
    },
    async EventGameNextRound(state, { game, loser, firstMove, secondMove, winnerHealth, loserHealth }) {
      if (!state.currentFighter) return;
      if (
        parseInt(game.opponent0) !== parseInt(state.currentFighter.nftId) &&
        parseInt(game.opponent1) !== parseInt(state.currentFighter.nftId)
      )
        return;

      console.log("GameNextRound", { game, loser, firstMove, secondMove, winnerHealth, loserHealth })

      state.finished = false;
      this.commit("SET_MOVES", {
        firstMove: Number(firstMove),
        secondMove: Number(secondMove),
      });
      this.commit("SET_WINNER", Number(loser));
      if (Number(loser) !== 0) {
        if (Number(loser) === Number(state.currentFighter.nftId)) {
          state.currentFighter.health = Number(loserHealth / 1000);
          state.opponent.health = Number(winnerHealth / 1000);
        } else {
          state.currentFighter.health = Number(winnerHealth / 1000);
          state.opponent.health = Number(loserHealth / 1000);
        }
      } else {
        state.currentFighter.health -= 15;
        state.opponent.health -= 15;
      }
      state.isFirstPlayer =
        Number(game.opponent0) === Number(state.currentFighter.nftId);
      state.lastTime = Number(game.timestamp);
      this.commit("gameNextRound", game);
    },
    async EventGameFinished(state, { game, loser, winnerHealth, amountWinner, amountLoser }) {
      if (!state.currentFighter) return;
      if (
        parseInt(game.opponent0) !== parseInt(state.currentFighter.nftId) &&
        parseInt(game.opponent1) !== parseInt(state.currentFighter.nftId)
      )
        return;
      this.commit("gameNextRound", game);
      console.log("GameFinished");

      state.finished = true;
      state.amountWinner = ethers.utils.formatUnits(amountWinner);
      state.amountLoser = ethers.utils.formatUnits(amountLoser);

      if (Number(winnerHealth / 1000) === 0) {
        this.commit("SET_WINNER", null);
      } else {
        this.commit("SET_WINNER", Number(loser));
      }

      if (Number(loser) === Number(state.currentFighter.nftId)) {
        state.currentFighter.health = 0;
        state.opponent.health = Number(winnerHealth / 1000);
        state.myDXP = Number(state.myDXP) + Number(state.amountLoser);
      } else {
        state.currentFighter.health = Number(winnerHealth / 1000);
        state.opponent.health = 0;
        state.myDXP = Number(state.myDXP) + Number(state.amountWinner);
      }
      this.commit("SET_WINNABLE", false);
    }
  },
  actions: {
    setPendingTransaction({ commit }, payload) {
      commit('SET_PENDING_TRANSACTION', payload)
    },
    async handleDisconnect({ state }) {
      state.library = null;
      state.provider = null;
      state.account = null;
      state.signer = null;
      state.chainId = null;
      state.wrongChainId = null;
      localStorage.setItem("disconnect", 1);
      this.commit("getmyDXP");
      this.commit("getMyDinos");
    },
    async handleAccountsChanged({ state }, accounts) {
      if (state.provider) {
        if (accounts.length > 0) {
          state.library = new ethers.providers.Web3Provider(state.provider);
          this.dispatch("fetchAccountData");
        } else {
          this.dispatch("handleDisconnect");
        }
      }
    },
    async connectNetwork({ state, commit }) {},
    async connectWallet({ state }, { connector, first }) {
      if (localStorage.getItem("disconnect") === "1" && first === 1) {
        return false;
      }
      localStorage.setItem("disconnect", "0");
      if (connector && connector.isInstalled()) {
        const provider = await connector.connect();
        if (provider) {
          state.isModalOpen = false;
          state.provider = provider;
          state.library = new ethers.providers.Web3Provider(provider);
          this.dispatch("fetchAccountData");
          provider.on("chainChanged", async (chainId) => {
            this.commit(
              "SET_WRONG_CHAIN_ID",
              chainId != process.env.VUE_APP_CHAIN_ID_HEX
            );
            if (chainId == process.env.VUE_APP_CHAIN_ID_HEX) {
              // Metamask recommend reloading the page, unless you must do otherwise
              window.location.reload();
            }
          });
          provider.on("accountsChanged", (accounts) =>
            this.dispatch("handleAccountsChanged", accounts)
          );
          provider.on("network", (newNetwork, oldNetwork) => {
            // When a Provider makes its initial connection, it emits a "network"
            // event with a null oldNetwork along with the newNetwork. So, if the
            // oldNetwork exists, it represents a changing network
            if (oldNetwork) {
              window.location.reload();
            }
          });
          provider.on("disconnect", () => this.dispatch("handleDisconnect"));
        }
      }

      // // HANDLE EVENTS
      // setInterval((state) => {
      //   this.dispatch("checkForEvents");
      // }, 2000);  
    },
    async disconnectWallet({ state }) {
      if (
        state.provider &&
        state.provider?.disconnect &&
        typeof state.provider?.disconnect === "function"
      ) {
        state.provider.disconnect();
      }
      this.dispatch("handleDisconnect");
    },
    async fetchAccountData({ state }) {
      if (state.library) {
        state.signer = state.library.getSigner();
        state.chainId = await state.signer.getChainId();
        const isWrongChainId =
          state.chainId !== parseFloat(process.env.VUE_APP_CHAIN_ID_NUMBER);
        if (isWrongChainId) {
          this.commit("SET_WRONG_CHAIN_ID", true);
          const lib = state.library;
          const account = await state.signer.getAddress();
          await lib?.send("wallet_addEthereumChain", [ChainParams, account]);
        } else {
          this.commit("SET_WRONG_CHAIN_ID", false);
        }
        if (!isWrongChainId) {
          const account = await state.signer.getAddress();
          this.commit("SET_ACCOUNT", account);
          this.commit("getAllNFT");
        }
      }
    },
    async checkForEvents({ state }) {
        const contract = this.getters.useFCContract;
        let currentBlock = await state.library.getBlockNumber();
        if (state.lastBlock == 0) state.lastBlock = currentBlock;
        
        if (currentBlock > state.lastBlock) {
            let fromBlock = state.lastBlock + 1;
            
            console.log(`Checking: From Block: ${fromBlock} -> Current Block: ${currentBlock}`);
            
            try {
                const events = await contract.queryFilter("*", fromBlock, currentBlock)
                
                state.lastBlock = currentBlock;
                
                if (events.length) {
                    for (var i = 0; i < events.length; i++) {
                        let event = events[i];
                        let eventName = event.event;
                        switch (eventName) {
                          case "GameStarted":
                            var { game } = event.args;

                            this.commit("EventGameStarted", { game });
                            break;
                          case "AttackCommitted":
                            var { game } = event.args;

                            this.commit("EventAttackCommitted", { game });
                            break;
                          case "RevealCommitted":
                            var { game } = event.args;

                            this.commit("EventRevealCommitted", { game });
                            break;
                          case "GameNextRound":
                            var { game, loser, firstMove, secondMove, winnerHealth, loserHealth } = event.args;

                            this.commit("EventGameNextRound", { game, loser, firstMove, secondMove, winnerHealth, loserHealth });
                            break;
                          case "GameFinished":
                            var { game, loser, winnerHealth, amountWinner, amountLoser } = event.args;
                            
                            this.commit("EventGameFinished", { game, loser, winnerHealth, amountWinner, amountLoser });
                            break;
                        }
                    }
                }
            } catch(e) {
                console.log(`Error Fetching Events`, e)
            }
        }
    }
  },
  getters: {
    useFCContract(state) {
      if (!state.signer) {
        return null;
      }
      return new ethers.Contract(fcContract, fcAbi, state.signer);
    },
    useDNSContract(state) {
      if (!state.signer) {
        return null;
      }
      return new ethers.Contract(dnsContract, dnsAbi, state.signer);
    },
    getModal(state) {
      return state.modal;
    },
    getCurrentFighter(state) {
      return state.currentFighter;
    },
    getCurrentManaged(state) {
      return state.currentManaged;
    },
    attackCommitted(state) {
      return (
        state.game && state.game.commit0 && state.game.commit0 !== ZERO_COMMIT
      );
    },
    revealCommitted(state) {
      return state.game && Number(state.game.reveal1) !== 0;
    },
    currentRound(state) {
      return state.game ? state.game.currentRound : 1;
    },
    getWrongChainId(state) {
      return state.wrongChainId;
    },
    getIsPendingTransaction(state) {
      return state.pendingTransaction;
    },
  },
});
