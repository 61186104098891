<template>
  <div>
    <FightModal />
    <div id="lobby" class="lobby container">
      <span class="lobbyTextTitle">JOIN LOBBY</span>
      <span class="lobbyTextTitle lobbyTextChoose"
        >CHOOSE YOUR NEXT FIGHTER</span
      >
      <template v-if="fetchingUserData">
        <h3 class="no-data">Fetching...</h3>
      </template>
      <template v-else>
        <section v-if="myDinos.length > 0">
          <fighter-card />
          <div class="button-start-container">
            <!-- <button @click="resetHealth">sss</button> -->
            <button
              class="buttonStartFight"
              @click="handleStart"
              :disabled="!currentFighter || getIsPendingTransaction"
            >
              {{ getIsPendingTransaction ? 'LOADING...' : 'START FIGHT' }}
            </button>
          </div>
        </section>
        <div class="no-data" v-if="myDinos.length === 0">
          <h3>You don’t have any dinos to fight.</h3>
        </div>
      </template>
      <div class="footerLogo">
        <img src="@/assets/images/Logo.png" class="logo" />
      </div>
    </div>
  </div>
</template>

<script>
import { ethers } from "ethers";
import { mapActions, mapGetters, mapState } from "vuex";
import FighterCard from "../components/FighterCard.vue";
import FightModal from "../views/FightModal.vue";

export default {
  name: "TheLobby",
  components: { FighterCard, FightModal },
  computed: {
    ...mapState([
      "myDinos",
      "currentFighter",
      "opponent",
      "fetchingUserData",
    ]),
    ...mapGetters([
      'getIsPendingTransaction'
    ])
  },
  methods: {
    ...mapActions([
      'setPendingTransaction'
    ]),
    ...mapGetters(["useFCContract", "useDNSContract"]),
    calculateGasMargin(value) {
      return value
        .mul(ethers.BigNumber.from(10000).add(ethers.BigNumber.from(5000)))
        .div(ethers.BigNumber.from(10000));
    },
    handleStart() {
      this.setPendingTransaction(true);
      const nftId = parseInt(this.currentFighter.nftId);
      const contract = this.useFCContract();
      if (contract) {
        contract.estimateGas
          .joinLobby(nftId, {})
          .then((estimatedGas) => {
            contract
              .joinLobby(nftId, {
                gasLimit: this.calculateGasMargin(estimatedGas),
              })
              .then((res) => {
                res.wait().then(() => {
                  this.setPendingTransaction(false);
                  this.$toast.success("Joined Lobby");
                  this.$store.commit("SET_MODAL", true);
                });
              })
              .catch((error) => {this.setPendingTransaction(false);
                this.$toast.error(error.message);
              });
          }).catch(error => {
            this.setPendingTransaction(false);
          })
      }
    },
    resetHealth() {
      // const contract = this.useFCContract();
      // if (contract) {
      //   contract
      //     .resetHealth(41)
      //     .then((res) => {
      //       res.wait().then(() => {});
      //     })
      //     .catch((error) => {
      //       this.$toast.error(error.message);
      //     });
      // }
      // const contract = this.useDNSContract();
      // if(contract) {
      //   contract.setCost(ethers.utils.parseEther('1000')).then((res)=>{
      //     res.wait();
      //   })
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
[disabled="disabled"] {
  opacity: 0.6;
  cursor: default !important;
}
.lobby {
  @include mq("tablet-wide", min) {
    max-width: 953px;
  }
  @media (max-width: 575px) {
    width: calc(100% - 24px);
    margin: 0;
  }
}
.lobbyContainer {
  margin: 0 auto;
  max-width: 952px;
  width: 100%;
  @include mq("tablet", max) {
    width: calc(100% - 24px);
    padding: 0 12px;
  }
}
.lobbyTextTitle {
  display: block;
  font-family: Dinosaur;
  font-size: 60px;
  line-height: 64px;
  color: #fff;
  @include mq("tablet-wide", max) {
    font-size: 50px;
  }
  @include mq("tablet", max) {
    font-size: 40px;
  }
}
.lobbyTextChoose {
  font-size: 18px;
}
.button-start-container {
  text-align: right;
}
.buttonStartFight {
  display: inline-block;
  border-radius: 24px;
  padding: 12px 40px;
  text-align: center;
  font-family: Dinosaur;
  font-size: 30px;
  line-height: 42px;
  color: #fff;
  background: #ffa035;
  box-shadow: 0px 4px 0px #d0410a;
  cursor: pointer;
}
.no-data {
  color: #fff;
  text-align: center;
  font-size: 30px;
  line-height: 60px;
}
</style>
