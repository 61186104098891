var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fight-wrapper fight-wrapper--round"},[_c('div',{staticClass:"fight-wrapper__top"},[_c('h2',{staticClass:"fight-wrapper__top-round"},[_vm._v("ROUND "+_vm._s(_vm.currentRound()))])]),_c('div',{staticClass:"fight-wrapper__content"},[_c('div',{staticClass:"fight-wrapper__battle fight-battle"},[_c('div',{staticClass:"fight-battle-player fight-battle-player--owner"},[_c('div',{staticClass:"fight-countdown",class:_vm.leftTime <= 0 && 'fight-countdown--zero',style:(("\n            visibility: " + (_vm.isMyturn() ? 'initial' : 'hidden') + "\n          "))},[_c('img',{attrs:{"src":require("@/assets/images/icon-timer.svg"),"width":"42","height":"48","alt":""}}),_c('span',[_c('span',[_vm._v(_vm._s(_vm.leftTime))]),_vm._v("  "),_c('span',{staticClass:"fight-countdown__text"},[_vm._v("SECONDS LEFT")]),_c('span',{staticClass:"fight-countdown__text-mobile"},[_vm._v("SEC")])])]),_c('div',{staticClass:"fight-player"},[_c('div',{staticClass:"fighter"},[_c('div',{staticClass:"fighter__photo-container"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.currentFighter.image),expression:"currentFighter.image"}],staticClass:"fighter__photo",attrs:{"alt":""}})]),_c('span',{staticClass:"fighter__name"},[_vm._v(_vm._s(_vm.currentFighter.name))]),_c('div',{staticClass:"fighter__info"},[_c('span',[_vm._v("LEVEL "+_vm._s(_vm.currentFighter.level))]),_c('span',[_vm._v(_vm._s(_vm.currentFighter.health)+"/100 HP")])]),_c('div',{staticClass:"fighter__progress"},[_c('span',{style:(("width: " + (_vm.currentFighter.health) + "%"))})])])])]),(
          !(_vm.isFirstPlayer && _vm.attackCommitted()) &&
          !(!_vm.isFirstPlayer && _vm.revealCommitted())
        )?_c('div',{staticClass:"fight-actions"},_vm._l((_vm.moves),function(move){return _c('button',{key:("" + (move.move)),staticClass:"fight-action",attrs:{"disabled":!_vm.isFirstPlayer && !_vm.attackCommitted()},on:{"click":function($event){return _vm.fightAction(move.move)}}},[_c('img',{staticClass:"fight-action__icon",attrs:{"src":require('@/assets/images/' + move.icon),"alt":""}}),_c('span',{staticClass:"fight-action__text"},[_vm._v(_vm._s(move.name)+"!")])])}),0):_vm._e(),_c('div',{staticClass:"fight-battle-player"},[_c('div',{staticClass:"fight-opponent"},[_c('div',{staticClass:"fighter"},[_c('div',{staticClass:"fighter__photo-container"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.opponent.image),expression:"opponent.image"}],staticClass:"fighter__photo",attrs:{"alt":""}})]),_c('span',{staticClass:"fighter__name"},[_vm._v(_vm._s(_vm.opponent.name))]),_c('div',{staticClass:"fighter__info"},[_c('span',[_vm._v("LEVEL "+_vm._s(_vm.opponent.level))]),_c('span',[_vm._v(_vm._s(_vm.opponent.health)+"/100 HP")])]),_c('div',{staticClass:"fighter__progress"},[_c('span',{style:(("width: " + (_vm.opponent.health) + "%"))})])])]),_c('div',{staticClass:"fight-countdown opponent-timer",class:_vm.leftTime <= 0 && 'fight-countdown--zero timer-opponent--zero',style:(("\n            visibility: " + (!_vm.isMyturn() ? 'initial' : 'hidden') + "\n          "))},[_c('img',{attrs:{"src":require("@/assets/images/icon-timer.svg"),"width":"42","height":"48","alt":""}}),_c('span',[_c('span',[_vm._v(_vm._s(_vm.leftTime))]),_vm._v("  "),_c('span',{staticClass:"fight-countdown__text"},[_vm._v("SECONDS LEFT")]),_c('span',{staticClass:"fight-countdown__text-mobile"},[_vm._v("SEC")])])])])])]),_c('div',{staticClass:"fight-wrapper__bottom"},[_c('div',{staticClass:"fight-wrapper__bottom-message"},[_c('span',[_vm._v(_vm._s(_vm.getGameState()))])]),(
        _vm.isFirstPlayer &&
        _vm.getRevealCommitted &&
        _vm.currentModal !== 1 &&
        _vm.currentModal !== 5
      )?_c('button',{staticClass:"fight-wrapper__bottom-message fight-wrapper__bottom-message--knockout-btn",on:{"click":_vm.reveal}},[_vm._v(" REVEAL ")]):_vm._e(),(!_vm.isUserTurn && _vm.leftTime <= 0)?_c('button',{staticClass:"fight-wrapper__bottom-message fight-wrapper__bottom-message--knockout-btn",on:{"click":_vm.handleKnockout}},[_vm._v(" KNOCKOUT ")]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }