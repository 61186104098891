<template>
  <div>
    <div
      class="row fightersToChoose"
      v-for="(row, rowIndex) in arrangedData"
      :key="rowIndex"
    >
      <div
        class="fighters-col"
        v-for="(item, index) in row"
        :key="`${rowIndex}:${index}`"
      >
        <button
          class="fighter"
          :class="
            item.health < 100
              ? 'fighter--inactive'
              : selected === item.index && 'fighter--selected'
          "
          @click="selectFighter(item.index)"
          :disabled="item.health < 100"
        >
          <div class="fighter__photo-container">
            <img class="fighter__photo" v-lazy="item.image" alt="" />
          </div>
          <span class="fighter__name">{{ item.name }}</span>
          <div class="fighter__info">
            <span>LEVEL {{ item.level }}</span>
            <span>{{ item.health }}/100 HP</span>
          </div>
          <div class="fighter__progress">
            <span :style="`width: ${item.health}%`"></span>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "FighterCard",
  computed: {
    ...mapState(["myDinos"]),
    arrangedData() {
      const arrangedData = [];
      this.myDinos.forEach((item, index) => {
        if (index % 4 === 0) {
          arrangedData.push([]);
        }
        item.index = index;
        arrangedData[arrangedData.length - 1].push(item);
      });
      return arrangedData;
    },
  },
  data() {
    return {
      selected: null,
    };
  },
  methods: {
    selectFighter(data) {
      this.selected = data;
      this.$store.commit("SET_CURRENT_FIGHTER", data);
    },
  },
};
</script>

<style lang="scss" scoped>
.fightersToChoose {
  margin-bottom: 38px;
}
.fighters-col {
  flex: 0 0 auto;
  width: 25%;
  margin-bottom: 16px;
  @include mq('tablet', max) {
    width: 50%;
  }
  @include mq('phone-wide', max) {
    width: 100%;
    padding: 0 32px;
  }
}
</style>
