<template>
  <div
    class="fight-wrapper-winner"
    :class="{
      'fight-modal--win': win === true,
      'fight-modal--lose': win === false,
      'fight-modal--draw': win === null,
    }"
  >
    <div class="fight-layout">
      <div class="fight-return">
        <div class="fight-return-btn cursor-pointer" @click="returnLobby">
          RETURN TO LOBBY
        </div>
      </div>
      <template v-if="win === null">
        <div class="fight-player__draw">
          <img src="@/assets/images/itsatie.png" alt="" />
        </div>
      </template>
      <div class="fight-battle-players">
        <div class="fight-player">
          <div
            class="fighter"
            :class="{
              'fighter--winner': win === true,
              'fighter--loser': win === false,
              'fighter--draw': win === null,
            }"
          >
            <div class="fighter__photo-container">
              <img
                class="fighter__photo"
                v-lazy="currentFighter.image"
                alt=""
              />
            </div>
            <span class="fighter__name">{{ currentFighter.name }}</span>
            <div class="fighter__info">
              <span>LEVEL {{ currentFighter.level }}</span>
              <span>{{ currentFighter.health }}/100 HP</span>
            </div>
            <div class="fighter__progress">
              <span :style="`width: ${currentFighter.health}%`"></span>
            </div>
          </div>
          <div class="fight-player__winner" v-if="win">
            <img src="@/assets/images/winner-crown.png" alt="" />
            <span>WINNER!</span>
          </div>
          <template v-else-if="win === false">
            <div class="fight-player__loser">
              <img src="@/assets/images/broke-bone.png" alt="" />
              <span>DEFEATED</span>
            </div>
          </template>
        </div>
        <div class="fight-opponent">
          <div
            class="fighter"
            :class="{
              'fighter--winner': win === false,
              'fighter--loser': win === true,
              'fighter--draw': win === null,
            }"
          >
            <div class="fighter__photo-container">
              <img class="fighter__photo" v-lazy="opponent.image" alt="" />
            </div>
            <span class="fighter__name">{{ opponent.name }}</span>
            <div class="fighter__info">
              <span>LEVEL {{ opponent.level }}</span>
              <span>{{ opponent.health }}/100 HP</span>
            </div>
            <div class="fighter__progress">
              <span :style="`width: ${opponent.health}%`"></span>
            </div>
          </div>
        </div>
      </div>
      <div class="fight-bottom-text fight-bottom-text--earned">
        {{
          win
            ? `YOU EARNED ${amountWinner} $DXP!`
            : `YOU EARNED ${amountLoser} $DXP!`
        }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "FightWinner",
  computed: {
    ...mapState([
      "currentFighter",
      "opponent",
      "win",
      "amountWinner",
      "amountLoser",
    ]),
  },
  mounted() {
    this.$stopLoop();
    if (this.win) {
      this.$playVictory();
    } else {
      this.$playLose();
    }
  },
  methods: {
    returnLobby() {
      this.$store.commit("SET_CURRENT_MODAL", 1);
      this.$store.commit("SET_MODAL", false);
      this.$store.commit("SET_CURRENT_FIGHTER", null);
    },
  },
  unmounted() {
    this.$store.commit("SET_CURRENT_ROUND", 1);
  },
};
</script>

<style lang="scss" scoped>
.fight-wrapper-winner {
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100% - 48px);
  @include mq('phablet', max) {
    padding: 16px 8px;
  }
}
.fight-battle-players {
  display: flex;
  justify-content: center;
  margin: 120px 0;
  @media (min-width: 992px) and (max-height: 889px) {
    margin: 80px 0 40px;
  }
  @include mq('tablet-wide', max) {
    margin: 80px 0 40px;
  }
}
.fight-layout {
  width: 100%;
}
.fight-modal--win {
  border-color: #feca35;
}
.fighter {
  max-width: 280px;
  width: 100%;
  @include mq('tablet-wide', max) {
    max-width: 240px;
  }
  @include mq('tablet', max) {
    max-width: 190px;
  }
  @include mq('tablet-small', max) {
    max-width: 150px;
  }
}
.fight-player {
  position: relative;
}
.fight-player,
.fight-opponent {
  width: 50%;
  display: flex;
  justify-content: center;
  @include mq('phablet', max) {
    margin: 0 8px;
  }
}
.fight-player__loser::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #df1a1a 39.06%,
    rgba(97, 15, 15, 0) 100%
  );
  opacity: 0.75;
  z-index: -1;
}
.fight-return {
  display: flex;
  justify-content: center;
  text-align: center;
  position: relative;
  z-index: 2;
}
.fight-return-btn {
  border-radius: 24px;
  padding: 12px 38px;
  font-size: 24px;
  line-height: 28px;
  color: #fff;
  background: #feca35;
  box-shadow: 0px 5.5px 0px #b98900;
  @include mq('phablet', max) {
    font-size: 16px;
  }
}
.fight-modal--lose .fight-return-btn {
  background: #bc4242;
  box-shadow: 0px 5.5px 0px #8e3333;
}
.fight-modal--draw .fight-return-btn {
  background: #364207;
  box-shadow: 0px 5.5px 0px #6A8112;
}
.fight-player__winner {
  position: absolute;
  top: -60px;
  width: 336px;
  height: 190px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 48px;
  color: #feca35;
  transform-origin: bottom right;
  transform: rotate(-16deg) translate(-90px, -100px);
  -webkit-text-stroke: 4px #fff;
  z-index: 0;

  @include mq('tablet-wide', max) {
    top: 0;
    transform: rotate(-12deg) translate(-10px, -150px);
  }
  @include mq('tablet-small', max) {
    -webkit-text-stroke: 2px #fff;
    font-size: 24px;
  }
  @include mq('phablet', max) {
    top: 0;
    transform: rotate(0) translate(0, -100px);
  }
}
.fight-player__winner::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #feca35 39.06%,
    rgba(254, 155, 53, 0) 100%
  );
  opacity: 0.75;
  z-index: -1;
}
.fight-bottom-text--earned {
  position: relative;
  border: 4px solid #fff;
  padding-left: 112px;
  padding-right: 112px;
  color: #fff;
  background: #53bc42;
}
.fight-bottom-text--earned::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 20px;
  width: 42px;
  height: 42px;
  transform: translateY(-50%);
  background: url(../assets/images/dxp.png) no-repeat center / cover;

  @include mq('tablet-wide', max) {
    width: 24px;
    height: 24px;
  }
  @include mq('phablet', max) {
    display: none;
  }
}
.fight-bottom-text {
  @include mq('tablet-wide', max) {
    font-size: 16px;
    padding: 23px 60px;
    white-space: nowrap;
  }
  @include mq('phablet', max) {
    white-space: initial;
    padding: 23px 20px;
  }
}
.fight-player__loser {
  position: absolute;
  top: -60px;
  width: 336px;
  height: 190px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 48px;
  color: #df1a1a;
  transform-origin: bottom right;
  transform: rotate(-16deg) translate(-90px, -100px);
  -webkit-text-stroke: 4px #fff;
  z-index: 0;

  @include mq('tablet-wide', max) {
    top: 0;
    transform: rotate(-12deg) translate(-10px, -150px);
  }
  @include mq('tablet-small', max) {
    -webkit-text-stroke: 2px #fff;
    font-size: 24px;
  }
  @include mq('phablet', max) {
    top: 0;
    transform: rotate(0) translate(0, -100px);
  }
}
.fight-player__draw {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: 30px;
  margin-bottom: -60px;

  img {
    width: 283px;
    height: 66px;
  }

  @include mq('tablet-small', max) {
    img {
      width: 209px;
      height: 49px;
    }
  }
}
.fight-modal--lose .fight-bottom-text--earned {
  background: #bc4242;
}

.fight-modal--draw .fight-bottom-text {
  background: #364207;
}
</style>
