var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fight-wrapper-winner",class:{
    'fight-modal--win': _vm.win === true,
    'fight-modal--lose': _vm.win === false,
    'fight-modal--draw': _vm.win === null,
  }},[_c('div',{staticClass:"fight-layout"},[_c('div',{staticClass:"fight-return"},[_c('div',{staticClass:"fight-return-btn cursor-pointer",on:{"click":_vm.returnLobby}},[_vm._v(" RETURN TO LOBBY ")])]),(_vm.win === null)?[_vm._m(0)]:_vm._e(),_c('div',{staticClass:"fight-battle-players"},[_c('div',{staticClass:"fight-player"},[_c('div',{staticClass:"fighter",class:{
            'fighter--winner': _vm.win === true,
            'fighter--loser': _vm.win === false,
            'fighter--draw': _vm.win === null,
          }},[_c('div',{staticClass:"fighter__photo-container"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.currentFighter.image),expression:"currentFighter.image"}],staticClass:"fighter__photo",attrs:{"alt":""}})]),_c('span',{staticClass:"fighter__name"},[_vm._v(_vm._s(_vm.currentFighter.name))]),_c('div',{staticClass:"fighter__info"},[_c('span',[_vm._v("LEVEL "+_vm._s(_vm.currentFighter.level))]),_c('span',[_vm._v(_vm._s(_vm.currentFighter.health)+"/100 HP")])]),_c('div',{staticClass:"fighter__progress"},[_c('span',{style:(("width: " + (_vm.currentFighter.health) + "%"))})])]),(_vm.win)?_c('div',{staticClass:"fight-player__winner"},[_c('img',{attrs:{"src":require("@/assets/images/winner-crown.png"),"alt":""}}),_c('span',[_vm._v("WINNER!")])]):(_vm.win === false)?[_vm._m(1)]:_vm._e()],2),_c('div',{staticClass:"fight-opponent"},[_c('div',{staticClass:"fighter",class:{
            'fighter--winner': _vm.win === false,
            'fighter--loser': _vm.win === true,
            'fighter--draw': _vm.win === null,
          }},[_c('div',{staticClass:"fighter__photo-container"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.opponent.image),expression:"opponent.image"}],staticClass:"fighter__photo",attrs:{"alt":""}})]),_c('span',{staticClass:"fighter__name"},[_vm._v(_vm._s(_vm.opponent.name))]),_c('div',{staticClass:"fighter__info"},[_c('span',[_vm._v("LEVEL "+_vm._s(_vm.opponent.level))]),_c('span',[_vm._v(_vm._s(_vm.opponent.health)+"/100 HP")])]),_c('div',{staticClass:"fighter__progress"},[_c('span',{style:(("width: " + (_vm.opponent.health) + "%"))})])])])]),_c('div',{staticClass:"fight-bottom-text fight-bottom-text--earned"},[_vm._v(" "+_vm._s(_vm.win ? ("YOU EARNED " + _vm.amountWinner + " $DXP!") : ("YOU EARNED " + _vm.amountLoser + " $DXP!"))+" ")])],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fight-player__draw"},[_c('img',{attrs:{"src":require("@/assets/images/itsatie.png"),"alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fight-player__loser"},[_c('img',{attrs:{"src":require("@/assets/images/broke-bone.png"),"alt":""}}),_c('span',[_vm._v("DEFEATED")])])}]

export { render, staticRenderFns }