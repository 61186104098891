<template>
  <div id="home">
    <div id="main" class="content">
      <div class="container">
        <div class="content-header">
          <a
            href="https://defidinos.gitbook.io/defi-dinos/"
            target="_blank"
            referrerpolicy="no-referrer"
            class="content-header__how-to"
          >
            GAME GUIDE
          </a>
          <a
            href="https://defidinos.gitbook.io/defi-dinos/nfts/marketplaces"
            target="_blank"
            referrerpolicy="no-referrer"
            class="content-header__how-to"
          >
            MARKETPLACES
          </a>
          <a
            href="https://snapshot.org/#/defidinos.eth"
            target="_blank"
            referrerpolicy="no-referrer"
            class="content-header__how-to"
          >
            DAO
          </a>
          <div class="content-header__icons">
            <a
              href="https://discord.gg/defidinos"
              target="_blank"
              referrerpolicy="no-referrer"
            >
              <img src="@/assets/images/discord.svg" alt="Discord" />
            </a>
            <a
              href="https://twitter.com/defidinos"
              target="_blank"
              referrerpolicy="no-referrer"
            >
              <img src="@/assets/images/twitter.svg" alt="Twitter" />
            </a>
          </div>
        </div>

        <div class="content-logo">
          <div class="content-logo__dd">
            <img
              src="@/assets/images/home/title-defi-dinos.png"
              alt="Defi Dinos"
            />
          </div>
          <div class="content-logo__fc">
            <img
              src="@/assets/images/home/title-fight-club.png"
              alt="Fight Club"
            />
          </div>
        </div>

        <div class="content-start">
          <router-link to="/lobby" tag="button" class="content-start__button">
            <img
              src="@/assets/images/home/title-start-game.png"
              class="content-start__button--desktop"
              alt="Defi Dinos"
            />
            <img
              src="@/assets/images/home/title-start.png"
              class="content-start__button--mobile"
              alt="Defi Dinos"
            />
          </router-link>

          <div class="content-start__text">
            YOU NEED AT LEAST 1 DEFI DINO TO PLAY
          </div>
        </div>

        <div class="content-info">
          <div class="content-info__item">
            <div class="content-info__item-icon">
              <img src="@/assets/images/icon_gamepad.png" />
            </div>
            <span class="content-info__item-label">QUICK, FUN GAMEPLAY</span>
          </div>
          <div class="content-info__item">
            <div class="content-info__item-icon">
              <img src="@/assets/images/icon_DXP.png" />
            </div>
            <span class="content-info__item-label">EARN $DXP</span>
          </div>
          <div class="content-info__item">
            <div class="content-info__item-icon">
              <img src="@/assets/images/icon_arrow.png" />
            </div>
            <span class="content-info__item-label">LEVEL UP YOUR DINOS</span>
          </div>
        </div>
      </div>
    </div>
    <div class="background">
      <lottie-animation :loop="true" path="animations/background.json" />
    </div>
  </div>
</template>
<script>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

export default {
  components: {
    LottieAnimation,
  },
  name: "TheHome",
};
</script>
<style lang="scss" scoped>
.content {
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.63) 0%,
    rgba(0, 0, 0, 0) 50%
  );
  display: flex;
  z-index: 1;
  padding: 40px 0 100px;
  text-align: center;
  @include mq('tablet', max) {
    padding-top: 20px;
  }
}
.content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
  &__icons {
    a {
      &:not(:first-child) {
        margin-left: 16px;
      }
    }
    img {
      width: 30px;
      height: 35px;
    }
  }
  a {
    color: #ffffff;
    font-size: 30px;
    text-decoration: none;
    transition: opacity 200ms ease-in-out;
    @include mq('tablet', max) {
      font-size: 18px;
      text-shadow: -4px 4px 4px #00000040;
    }
    &:hover {
      opacity: 0.6;
    }
  }
}
.content-logo {
  margin-bottom: 80px;
  @include mq('tablet', max) {
    margin-bottom: 60px;
  }
  &__dd img {
    margin-bottom: 12px;
  }
  img {
    max-width: 434px;
    width: 100%;
    @include mq('tablet', max) {
      max-width: 280px;
    }
  }
}
.content-start {
  margin-bottom: 60px;
  &__button {
    margin: 0 auto 16px;
    border: 6px solid #d0410b;
    border-radius: 10px;
    background: #ff9f34;
    font-size: 31px;
    padding: 16px 60px 10px;
    box-shadow: 0px 1px 2px rgb(0, 0, 0, 0.6);
    transition: 300ms;
    @include mq('tablet', max) {
      padding: 14px 20px 12px;
      max-width: 240px;
    }
    &:hover {
      background: #d0410b;
      border-color: #fff;
      box-shadow: 0 0 15px #850303;
    }
    &--desktop {
      @include mq('tablet', max) {
        display: none;
      }
    }
    &--mobile {
      display: none;
      @include mq('tablet', max) {
        display: block;
      }
    }
  }
  &__button img {
    max-width: 329px;
    width: 100%;
    @include mq('tablet', max) {
      max-width: 120px;
    }
  }
  &__text {
    font-size: 18px;
    color: #ffffff;
    text-shadow: -4px 4px 4px #00000040;
    @include mq('tablet', max) {
      font-size: 14px;
    }
    @include mq('phone-wide', max) {
      font-size: 12px;
    }
  }
}
.content-info {
  display: flex;
  justify-content: center;
  @include mq('tablet', max) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  &__item {
    display: flex;
    flex-direction: column;
    color: #ffffff;
    position: relative;
    padding: 30px 60px;

    @include mq("desktop-wide", max) {
      padding: 30px;
    }

    @include mq("tablet-wide", max) {
      padding: 10px 20px;
    }
    @include mq("tablet", max) {
      padding: 0 8px;
      text-align: left;
      flex-direction: row;
      align-items: center;
    }

    &::before {
      position: absolute;
      display: block;
      content: " ";
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      border: 4px solid #ffffff;
      border-radius: 20px;
      transform: skew(332deg);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
        0px 4px 4px rgba(0, 0, 0, 0.25);

      @include mq("tablet-wide", max) {
        transform: skew(350deg);
      }
      @include mq("tablet", max) {
        opacity: 0;
      }
    }
    &:not(:first-child) {
      margin-left: 28px;
      @include mq("tablet", max) {
        margin-left: 0px;
        margin-top: 10px;
      }
    }
  }
  &__item-icon {
    height: 43px;
    margin-bottom: 16px;
    img {
      height: 100%;
    }
    @include mq("tablet", max) {
      margin-bottom: 0;
      margin-right: 10px;
    }
  }
  &__item-label {
    font-size: 28px;
    text-shadow: -4px 4px 4px #00000040;
    @include mq("desktop", max) {
      font-size: 20px;
    }
    @include mq("tablet-wide", max) {
      font-size: 16px;
    }
  }
}
.background {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  background: rgb(80, 103, 18);
  background: linear-gradient(
    90deg,
    rgba(80, 103, 18, 1) 0%,
    rgba(132, 146, 1, 1) 100%
  );
  @include mq('tablet', max) {
    background: linear-gradient(90deg, #506712 0%, #5a6e00 100%);
  }
  > div {
    height: auto !important; /* fixes video to top- */

    @include mq('tablet', max) {
      width: 1440px !important;
    }
  }
}
</style>
