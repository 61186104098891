import { ethers } from "ethers";

export const ZERO_COMMIT = "0x0000000000000000000000000000000000000000000000000000000000000000";

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value) {
  try {
    return ethers.utils.getAddress(value);
  } catch {
    return false;
  }
}
export function shortenAddress(address, chars = 4) {
  const parsed = isAddress(address);
  if (!parsed) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }
  return `${parsed.substring(0, chars + 2)}-${parsed.substring(42 - chars)}`;
}
export function getScanLink(data, type) {
  const prefix = "https://cchain.explorer.avax.network";
  switch (type) {
    case "transaction": {
      return `${prefix}/tx/${data}`;
    }
    case "token": {
      return `${prefix}/token/${data}`;
    }
    case "block": {
      return `${prefix}/block/${data}`;
    }
    case "address":
    default: {
      return `${prefix}/address/${data}`;
    }
  }
}
export function calculateGasMargin(value) {
  return value
    .mul(ethers.BigNumber.from(10000).add(ethers.BigNumber.from(1000)))
    .div(ethers.BigNumber.from(10000));
}
