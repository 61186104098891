<template>
  <div class="fight-wrapper fight-found">
    <div class="fight-wrapper__top">
      <h2 class="fight-wrapper__top-round">ROUND {{ currentRound() }}</h2>
    </div>
    <div class="fight-wrapper__content">
      <div class="fight-wrapper__players">
        <div class="fighter fight-player">
          <img class="fighter__photo" v-lazy="currentFighter.image" alt="" />
          <span class="fighter__name">{{ currentFighter.name }}</span>
          <div class="fighter__info">
            <span>LEVEL {{ currentFighter.level }}</span>
            <span>{{ currentFighter.health }}/100 HP</span>
          </div>
          <div class="fighter__progress">
            <span :style="`width: ${currentFighter.health}%`"></span>
          </div>
        </div>
        <div class="fight-vs"></div>
        <div class="fighter">
          <img class="fighter__photo" v-lazy="opponent.image" alt="" />
          <span class="fighter__name">{{ opponent.name }}</span>
          <div class="fighter__info">
            <span>LEVEL {{ opponent.level }}</span>
            <span>{{ opponent.health }}/100 HP</span>
          </div>
          <div class="fighter__progress">
            <span :style="`width: ${opponent.health}%`"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="fight-wrapper__bottom">
      <div class="fight-wrapper__bottom-message">
        <span>{{ getTitle }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { ethers } from "ethers";
import { mapGetters, mapState } from "vuex";

export default {
  name: "FightFound",
  data() {
    return {
      countdownStart: null,
      count: 3,
    };
  },
  computed: {
    ...mapState(["currentFighter", "opponent"]),
    getTitle() {
      return `${
        this.currentRound() === 1 ? "GAME" : "NEXT ROUND"
      } WILL START IN ${this.count}`;
    },
  },
  methods: {
    ...mapGetters(["currentRound"]),
    handleModal() {
      this.$store.commit("SET_CURRENT_MODAL", 3);
    },
  },
  mounted() {
    if (ethers.BigNumber.from(this.currentRound()).eq(ethers.BigNumber.from(1))) {
      this.$playIntro();
    }
    this.countdownStart = setInterval(() => {
      this.count--;
      if (this.count < 0) {
        clearInterval(this.countdownStart);
        this.handleModal();
        this.$playLoop();
      }
    }, 1000);
  },
  unmounted() {
    if (this.countdownStart) {
      clearInterval(this.countdownStart);
    }
  },
};
</script>

<style lang="scss" scoped>
.fight-vs {
  flex: 1;
  align-self: stretch;
  background: url(../assets//images/fight-vs.png) no-repeat center / contain;
  transform: scale(1.2);
  @include mq("tablet-wide" max) {
    transform: scale(1);
  }
  @include mq("tablet-small", max) {
    height: 150px;
    width: 125px;
    position: absolute;
    top: calc(50% - 75px);
    left: calc(50% - 63px);
    z-index: 1;
  }
}
</style>
