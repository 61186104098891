<template>
  <div id="app">
    <Loading v-show="isLoading" />
    <ManageModal v-if="isManageModalOpen" />
    <WalletModal v-show="isModalOpen" />
    <div class="background"></div>
    <div class="darker"></div>
    <navbar v-if="!isHome" />
    <main
      :class="{
        'main-wrapper': !isHome,
      }"
    >
      <router-view />
    </main>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import Navbar from "./components/Navbar.vue";
import Loading from "./components/Loading.vue";
import WalletModal from "./components/WalletModal.vue";
import ManageModal from "./components/ManageModal.vue";
import abi from "./constants/abi.json";

const noNavbars = ["home", "fight", "fightFound", "fightRound"];
export default {
  name: "App",
  components: { Loading, Navbar, WalletModal, ManageModal },
  data() {
    return {
      lastBlock: 0,
      polling: null,
    }
  },
  computed: {
    isHome() {
      return noNavbars.find((route) => route === this.$route.name);
    },
    ...mapState(["account", "isLoading", "isManageModalOpen", "isModalOpen"]),
    ...mapGetters(['useFCContract']),
  },
  created() {
    console.log('created polling')
    this.checkEvents();
    this.polling = setInterval(() => {
      this.checkEvents()
    }, 2000);
  },
  destroyed() {
    clearInterval(this.polling);
  },
  methods: {
    ...mapActions([
      'checkForEvents'
    ]),
    async checkEvents () {
      if (!this.account) return;
      if (!this.useFCContract) return;
      
      this.checkForEvents();
    }
  }
};
</script>

<style lang="scss">
@import "~bootstrap/scss/bootstrap-grid.scss";
@import "./assets/scss/main.scss";

body {
  font-family: $font;
  margin: 0px;
}
ul {
  list-style: none;
}
a {
  text-decoration: none;
}
button {
  cursor: pointer;
  border: none;
}
.background {
  position: fixed;
  background: url("./assets/images/cave.png") center center;
  background-size: cover;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.darker {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  flex-grow: 0;
  padding: 0;
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
    ),
    linear-gradient(
      to bottom,
      #231a2c 12%,
      rgba(46, 31, 46, 0) 21%,
      rgba(95, 61, 60, 0) 74%,
      #231a2c 89%
    );
  z-index: -1;
}
.main-wrapper {
  padding-top: 140px;
}
.footerLogo {
  width: 100%;
  margin: 40px auto;
  text-align: center;
}
.footerLogo img {
  width: 200px;
}

body,
.cursor-default {
  cursor: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAcCAMAAABWBG9SAAACPVBMVEUAAAD//////4CAgID/qlWqVVW/gEC/QECAQECZZjOZMzOAKysAAAAAAAB6JxYAAAAAAAAAAAAAAAAAAAAAAABbAAAAAAAAAAA9AAAAAAAPAAAhAAAYAAAaAAAyAAA6AABWAAA7AAA/AABCAABGAABJAAA/AAB0HBV2HhJyGBJ5KheGOBtwJxSJORuKOxyeTiOELhikVSaOPh6HLhi2ZCyrWimOQyajNRu4Zi6iMxqeIRadHBWfHxb/r1y/eki3ZCy2Yiu1XyqzWiiyVyewVCavUCWtTCSsSSOrRiGpQSCnPh+mOR2jMxuiLxqhKRmgJRemNRz8kkH/pEj/o0j/okj/nkL/plL/pUr/pE//okv/oUn/pEr/oEn/n0j/n0b/nkX/nUb/nUX/nUT/nUP/nEP/p07/pkz/pUr/pUn/n0a+ai/fQyP/vXL/t2z/tm3/tmv/tWn/s2b/sWP/sGD/rV3/rFr/qlf/qFL/p1D/pkr/o0n/o0j/okj/oUj/oUf/oEf/n0j/n0f/n0b+n0f+n0b/nkb+nkf+nkb/nUb9nkb+nUX+nUT9nUb/nEX+nEP+m0X8nEX9nEL+mkX9mET4mkX8l0PinV37lUL6k0L5kUHylELxlELvkUD3jT/vkT/1ij7lj0bnjD7WkFXzhTzwfznsdjbDf0vEfknBfkvQejfGfETFfETPeTbGe0G+fErNeDXHeT29e0rMdzXJdzq8ekrmaTC7eUm6eEe5dka3dETDbzG0cEHZSCTBPR+8PB6qN6ytAAAAbHRSTlMAAQICAwMEBAQFBQYLFC4vODxCRkhJTE5PUVNVVVdbYWJjZWhqbW53f3+Tlpiqtb3B0N/g5u7z9PX19fX2+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pn6+/v7+/z8/Pz8/f39/f39/f39/f7+/v7+/v4LpsI5AAABXElEQVR42mXP03oDURSG4VUkqW3btm3btm1Np01nF6lt23avrXuStmmS7/B91sG/wMUKmCBczamFqHp135uLaCBReWMmrEFEUcWlqZAGk1RR+ZmJoIaQbKq469xYQENHhhFV3HlsCNJ8DMM4RJV0HBn80/BRfDlElbWf6PM1YpJEw4ikStuu9P40cppEXC1pvdX51ag5ArHZWFFhy4P2j0bPY+RpQfOTJsjQGLNIoB8dz2t61uBq7PIANoxYJ7IbX9RojVvp5yF9O5VV/6oKshC/ipEb3jA4m9HwrgKQsNmHaEF4Gac6J92/LkAdErf7EKKJk59SRSxs7SzteYDffi9NY2m5M7vXbo62lkZaCuB7gJGT1rN+52ytJAe8fA97OfmZa2/2yoATZ7JYUhLg89iTSl44KAKIsRhiwMv7I3nDXReAyQB+nrWfTvIgSRO/pC87wJ8J5moDwBCyb+wrg2cbEKxwAAAAAElFTkSuQmCC")
      1 1,
    default;
}

a,
button,
.cursor-pointer {
  cursor: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAbCAMAAABLAV/qAAACu1BMVEUAAAD/////AAAAAACAgICAAACqVVWqAAAAAAC/gEAAv0C/QECAQEAAAADMZjMAAAAAAAAAXS4ASx4ANhsAAAAAPR8AAAAAAAAoQCAAAAAAHQ8AAAAAAAAAAAAAAAAAAAAAAAA8AAAAAAAAAABbAABWAABVLBZpABF2MBZiFBF0HBJtAABoGBF5Hg4uAABxJRWORiJoAAhlAAaFOB6DMRqEMhmOPx6EGROQQR6LBBCWTyyWRyGVRiGJFBLAbjGoWyuGRCy2Zy6PEBK/bDGXHRSoUSaWEBO5aC67aS66aS69azC5aC+0Yy21ZCycGxWXHRWxcUScKxejIBWkLRmIOx6STi6tMxy/fEj/pkrOeTaqWij/sVv/qkz/pEq7eUf/o0j/okj/nEb/mETDbzLAazH/t2f/oUf/nEbQgEC9e0jCcDL/slv/qlD/qUz/okn7j0H/qlf/pEr/o0r/okn/oUn9n0vPhkzagTjDcDH/pkn/pUv/pEn/oUf/n0b7nEXik07Sj1TQhUjPgkPFfki/bjOvXyn/um/9sGXzrGf7qFv/pkr/pEr/pEn/pEj/o0z/o0n/o0j/okr/okn/okj/okX/oUj/oEf+oEf/oET/oEP/n0f/n0b+n0b9n0b+nkb9nkb+nUb9nUb/nUD8nUb8nEXooV/7nEX8m0X7m0X5m0Xnn133mUT2mET2mEPgm13ylUL6kUHylELZlVnrkEDqjj/pjj/pjT/hjkfmiz7hjEPygTriiDzihzzghzzfhz3QiE3uejfYgTnVfjjUfjjndDXQfj3CgEzjcDPNeDXMdzXDeUDibDHlajHEdzzIdTTIdDS6d0bEczXGcjPGcjHeZi/FcjLEcTLDcTLDcDK/bjK9bjXQYSzRYCy5aS/eVinXWSquZTWwYCuuXyqmWCe2TyXDRyLDRiLEPyC3MRvAENN4AAAAhnRSTlMAAQEBAgIDAwMEBAQEBAUFBgsRExYZHB4gISMlJicoMDEzODxDREVJW1tjaWxubm94e3ySlpmjpKi0uMLCxMrP0tPT1tbZ2dzd3d/f4+bo6+/v7/H09vb3+Pj4+fn5+fr6+vr6+vv7+/v7+/z8/Pz8/f39/f39/f39/v7+/v7+/v7+/v7+/ndNd6MAAAHASURBVHjaXdB1cxNBGMfxHzQ9KLS4F3d3d3d3d5dC8LYUdygJHHfANmwIwZ26u7u7+8vos2k70/b7z8597nlmZxaUhKEbU8fR0bRWWPDk4Y5udDa1jqs/XrjdH5pm2HaFm9NV+xZot9LN6U5f2LZA5nzXHpCkDpoG0wjUnew3oCeoNjY2rSERC9y10P3UsBFTBsFS1x6wW8W0GVFnHm0Lebx/4OgxnTByS+VErGHalGvvrpTe3PN5648/s7GpWM3qvYRpk8OefXrw+/Wt/LPHorE8cq/v+Plcmxvx3Fz209Xn/tsX9zCp8MC3uYvUywFesrnil6tfnv5lPPps//f06G6dSVE5D/qiCy7Sv0oCZsWcCyzXc84Zk7niEa4oacCo6hPf01WB3Mi5qpjkbKDLZn9nTyPjBooz+knYHjNKjjP6oixolHMgYXjteZmWDQINArPFcy9NvMRJ6qP166D9CTWHVV6/TrmwdaDRzutDL35gjejwdybE6NiqQ2843U/IzI6xQwAxOy9z33tSyrzTaxpEGnTfkHDwhsnIXRwcvedYwZItei0u+CrLMvsfN9UK1o3abvKyI1mn104fDFijDgWjxJOCQASKAAAAAElFTkSuQmCC")
      7 0,
    pointer;
}

a:active,
.cursor-pointer-active {
  cursor: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAbCAMAAABLAV/qAAACglBMVEUAAAD///8AAACAgICqqlWqVVUAAAC/gICAgIAAAACZZmYAAAAAACuLXV14WlprUVEAAC56UlJJLkkAADx4UFAAAC5mSUkVAD4AAD4AADwAADQAADkAAEAAAD5BGUFEKUREK0RfPUhTLUdvSkppQklwSUxqQ0lsRkhhOkZqRElrQ0pWNUNuR0p5UU1kQEZjPkhzTUtwSUlxSUl3TUtrREh2TUxpQ0iCV056UExrREiQYFKFWU99VUuLXFFqREeQXlFsRUmAU05rREiMXlGNXVKNXVGPX1GNXlCJW1GJXFFtRUdtRUmbb1NwR0lvRklxSUl1TUqBV05zS0qhdFa5fF+YZlSFVk7JjGK7f1+3fF6gclW4e1+zd12vdVySYVOQX1LQlmW2e12zeVyhc1WgbVWRYVLIjWK+gmC7fl+1el2pcFjDh2G4fV+4fF+3fF+2fF+1fF62e16oeFidaVaRYVO6fV66fF65fF6xgFm4e162eV2we1q0eFyyd1ymdFejc1aicFaSYVKHWE/YnmbNlWPLlWLBjF/DimC/i169iV23hVy6fV+4fV64fF63fF63e162e123el62el21el21eV20eV20eVy1eF2zeV2zeVy0eF2zeF2zeFyyeFyzd12yd1yzdl2xd1yvdluqeVivdVuwdFyudVuqdVmsc1qld1aqclmncliocFmncFmnb1ilblijbVeecFSibVeibFeea1WcbFSfaVWdaVWbZ1WZaFSaZlSXZFSXZFOXY1OUY1OUYlOTYlKUYVKSYlKTYVKSYVKSYFKRYFKOYVCRX1GOXVCNXVGKWk+JWk+HWU+GWE+GV06FVk2CVk5/U018UEt6Tkp0SkmSFVFTAAAAg3RSTlMAAQECAwMDBAQEBQUGCxETFhkcHiAhIyUlJicoMDEzODxDREVJW1tjaWxubm94e3ySlpmjpKi0uMLEys/S09PW1tnZ3N3d39/j5ujr7+/v8fT29vf4+Pj5+fn5+vr6+vr7+/v7+/v8/Pz8/P39/f39/f39/f3+/v7+/v7+/v7+/v7+/n2x4V0AAAGxSURBVHjaXcv1VxVBHAXw+1hXF7u7uzuwu7sDu1FsEQO7A1fnMSsr36c8uru7u+P/YWaBc4A758wPn++9EFExZV/iXPRC19iw9vP7o4Nh62b9doTefjoOSjfsvZV7PRzdA123/fV6NQZaD+Teb0YBqtpX6TBFon517PhhEFE1zQZVsMTj675dmTp98URYGTQUrtu5Z2nK9Q+Hgz+emTBrdn/MONi0ADv5nfxHgffLX578dOjL11XY32AvHrFRYLrv/3dhf14U3bgYiS25p+LnrSHPsgxfR22QHv7234/XWFjj7nRbzx8kxDJHY4geXfb7ZypGHon6fuGETnY7UbJTT6rSf2UCKwtvJtfpRMQ5IxaTwVgeMLP5srPATyLJz06sFBh4IO1ZrBDDNP2tAyuBhuX1lziJZ5i8E1VMa73FSJoZYBimhTZgU8U9Imvejo8BDfNbzvmRNTfE3IfvhqgO2JN1lzjJORF5RKyArM5pPsul+ou+40n2ZEB2V1eeDhAq4jgWtxQyCobsrXB/LsjHwzvOzQVWNAzfUO1kjPGInCUuUDq1z6LN54uv7Vo2CVDQBlVovDWHqkQgAAAAAElFTkSuQmCC")
      7 0,
    pointer;
}

[disabled],
.cursor-disabled {
  cursor: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAcCAMAAABWBG9SAAAB+1BMVEUAAAD///////+AgICqqqpVVVWAgIBmZmZVVVUAAAAAAABTTkgAAAAbEg4iHhUAAAAAAAAAAAA/OzQHAAANAAAtKiATBgAZDwAzMCobFQAgGgkqJBwvLSU8OTE0Lik1Mi07ODE8OjU/OjY8ODNPS0dQTEZOSkRSTklYVU5RTUhZVk5aVlBiYFlTUUtnYl1aWFFTUUtwbGVpZ19dWlVYVU9wbWZWU05RTkpQTUlSTkqin5Z9enNvbGVua2RsaWJqZ2BpZV9nZF1lYlxjYFlhXldfXFVdWVRcWFJZVlBXVE9VUk1TUEtST0pXVVCPi4Oblo2alo2ZlYyYk4qdmZCdmY+bmI+alo2bl46YlIuYlIqXk4qXk4mXkomWkomempGdmZCdmY+cmI+YlItzcGhhXVmsqaKopZ2npJ2mo5ylopukoZmjoJiinpahnZWgnJSem5KdmpGdmZCbl46bl42alo2aloyZlYyZlYuYlIuYlIqXlIuXlIqYk4uYk4qXk4uXk4qWkoqWkomUkYuVkYiUkIiUkIeTj4eSjoaRjYWQjISQjIOOioKMiYKMiYGMiICKhn6JhX6GgnuCfnd/fXZ/fHZ+e3R9e3V+enN9enR9enN8enR9eXJ8eXN8eXJ8eHF7eHJ7eHB6d3F4dW92c212c2t1cWtiX1pdWVRcWFMbJUNiAAAAZXRSTlMAAQICAwMEBQYLFC4vODxCRkhJTE5PUVNVVVdbYWJjZWhqbW53f3+Tlpiqtb3B0N/g5u7z9PX19fX2+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pn6+/v7+/z8/Pz9/f39/f39/v7+/v7+/svC3FYAAAFYSURBVHjaZc/lUoJREIDhNcDu7u7u7u5WxO5uxAPot2IrtmKh2HGZngMqAu/+e2ZnZxYSwoAPhg1dhxhr2oI62EhzSf9DkKHmiYV9t4EGmk9Q2KsM0NcCIsGueaW/nhZyBLnuuUtfsNBhERIkXM/shc8/LV4nyHTm0lunJXKCbHqmr7z+tHSToEa7pm48frVsi6BMxlQ4eef+o+U7K4hkjWnnxL2rVit2xSiVrkqZCsYfXTRauSdGjqxyhKq8fezZiWnVAd0kHEWmbaMvjmAJ1YpllEnWZBIJMm0Ze3UAqDkW0ZuIlBAH25uzR3KcofZUhFoSNAyItxVHG4cpkHW2pKGmDvmJOik2MtTPzQYyz5eQ0uLBU3y4nRVoy1SJ5ILW/bdoe6CZ8mlmkPG+2EhUMbYAJnyeCWhL/6hXJHsC8HmgK3X4M84azBnpqvuKAvqZfokRADwD+wYbk3lOMNk93AAAAABJRU5ErkJggg==")
      1 1,
    default;
}
</style>
